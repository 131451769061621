import { Box, Menu, Skeleton, Typography } from "@mui/material";
import ErrorMessage from "./ErrorMessage";
import ImageWithLoader from "./ImageWithLoader";

export default function NavBarMenu({
  openMenu,
  closeMenu,
  data,
  loading,
  error,
}) {
  return (
    <Menu
      disableScrollLock={true}
      open={Boolean(openMenu)}
      onClose={() => closeMenu(null)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      PaperProps={{
        elevation: 0,
        sx: {
          p: "1em 1.3em",
          backgroundColor: "rgba(245, 255, 255, 0.5)",
          boxShadow: "20px 4px 30px 0 rgba(69, 90, 100, 0.2)",
          borderRadius: "10px",
          width: "90%",
          overflowY: "auto",
          overflowX: "hidden",
          mt: "70px",
        },
      }}
    >
      {error ? (
        <ErrorMessage message={error} />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "1em",
            flexWrap: "wrap",
          }}
        >
          {data?.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                borderRadius: "10px",
                cursor: "pointer",
                width: "fit-content",
                height: "150px",
              }}
              onClick={() => closeMenu(item?.id)}
            >
              {/* {loading ? (
                <Skeleton
                  variant="rounded"
                  // animation="wave"
                  sx={{ bgcolor: "grey.900" }}
                >
                  <img
                    src={item.image}
                    alt={`${index + 1}`}
                    style={{
                      width: "250px",
                      height: "150px",
                      borderRadius: "10px",
                      objectFit: "cover",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    loading="eager"
                  />
                </Skeleton>
              ) : (
                <img
                  src={item.image}
                  alt={`${index + 1}`}
                  style={{
                    width: "250px",
                    height: "150px",
                    borderRadius: "10px",
                    objectFit: "cover",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  loading="eager"
                />
              )} */}
              {item?.image ? (
                <img
                  src={item.image}
                  alt=""
                  style={{
                    width: "250px",
                    height: "150px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <ImageWithLoader />
              )}
              <Typography
                variant="subtitle1"
                sx={{
                  bgcolor: "rgba(1, 12, 25, 0.85)",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: "100%",
                  textAlign: "center",
                  color: "#FFF",
                  fontWeight: "400",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                {item.title}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Menu>
  );
}
