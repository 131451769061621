export default function WhyUs3() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2557_13125)">
        <path d="M12 0C9.32588 0 6.78438 0.901428 4.56093 2.64844L3.44984 1.84698C3.44435 1.99127 3.24129 7.21106 3.25319 6.9093C3.39949 6.85529 8.30489 5.04108 8.00624 5.15149C7.86543 5.05023 6.81075 4.29089 6.95155 4.39215C8.48616 3.35248 10.2698 2.85938 12 2.85938C17.0344 2.85938 21.1406 6.96552 21.1406 12C21.1406 13.7296 20.6625 15.3468 19.8046 16.725L22.0125 16.1625L21.5766 19.1439C23.0672 17.1469 24 14.6719 24 12C24 5.40472 18.5952 0 12 0Z" />
        <path d="M12 24.0001C13.8422 24.0001 15.6 23.5313 17.1608 22.772H17.1749C17.6109 22.561 18.0469 22.3219 18.4547 22.0547C18.5964 22.1824 19.7214 23.2416 19.5797 23.1142C19.6009 22.9714 20.3694 17.7483 20.3249 18.0469L15.403 19.2985L16.2609 20.0719C14.8348 20.8294 13.407 21.1407 12 21.1407C6.96552 21.1407 2.85938 17.0345 2.85938 12.0001C2.85938 10.6641 3.14062 9.3985 3.66101 8.2594L1.76239 8.96252L1.9032 5.61572C0.721802 7.45795 0 9.65173 0 12.0001C0 18.5953 5.40472 24.0001 12 24.0001Z" />
        <path d="M14.1094 7.78125C14.1094 8.94617 13.1649 9.89062 12 9.89062C10.8351 9.89062 9.89062 8.94617 9.89062 7.78125C9.89062 6.61633 10.8351 5.67188 12 5.67188C13.1649 5.67188 14.1094 6.61633 14.1094 7.78125Z" />
        <path d="M7.78125 14.1096V16.9221H16.2188V14.1096C16.2188 12.5818 15.3988 11.2559 14.1824 10.5156C13.5809 10.997 12.8286 11.2971 12 11.2971C11.1714 11.2971 10.4191 10.997 9.81757 10.5156C8.6012 11.2559 7.78125 12.5818 7.78125 14.1096Z" />
      </g>
      <defs>
        <clipPath id="clip0_2557_13125">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
