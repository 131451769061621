import { Box, Typography } from "@mui/material";

export default function TopBanner({ data }) {
  return (
    <Box
      sx={{
        backgroundImage: "url('/images/useCaseBannerBg.svg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        p: "1em 2em",
        mt: "5em",
        display: "flex",
        flexDirection: "column",
        gap: "0.7em",
      }}
    >
      <Typography variant="h6" sx={{ color: "#1C1C1C" }}>
        {data?.useCaseTitle}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "700", color: "#1C1C1C" }}>
        {data?.useCasedetail}
      </Typography>
    </Box>
  );
}
