import { Box, Button, Container, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import {
  getIndustryAction,
  getIndustryAppAction,
} from "../../store/actions/apps";
import { getIndustryAppsAction } from "../../store/actions/home";
import EmptyPage from "../../components/EmptyPage/index";
import ImageWithLoader from "../../components/ImageWithLoader/index";
import { getAllCategoriesAction } from "../../store/actions/apps";
import { useInView } from "react-intersection-observer";
import DiscoveryItems from "../../components/DiscoveryItems";
import ItemCard from "./../../components/ItemCard";
import { getAllProgramsAction } from "../../store/actions/programs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OurApps = () => {
  const [value, setValue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["common"]);
  const { id } = useParams();
  const dispatch = useDispatch();

  const industry = useSelector((state) => state.home.industryList);
  const industryLoading = useSelector(
    (state) => state.home.industryAppsLoading
  );

  const loading = useSelector((state) => state.apps.loading);

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const getIndustry = useCallback(() => {
    dispatch &&
      getIndustryAction({ langId, industryId: parseInt(id) })(dispatch);
  }, [dispatch, langId, id]);

  useEffect(() => {
    getIndustry && getIndustry();
  }, [getIndustry]);

  const getCategories = useCallback(() => {
    dispatch && getAllCategoriesAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getCategories && getCategories();
  }, [getCategories]);

  // const getApps = useCallback(() => {
  //   dispatch &&
  //     getIndustryAppAction({
  //       langId,
  //       ...(selectedCategory > 0 && { categoryId: selectedCategory }),
  //       industryId: parseInt(id),
  //     })(dispatch);
  // }, [dispatch, langId, selectedCategory, id]);

  // useEffect(() => {
  //   getApps && getApps();
  // }, [getApps]);

  const handleClick = (id) => {
    navigate(`/program/${id}`);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const handleFilterClick = (id) => {
  //   setSelectedCategory(id);
  // };

  const programsData = useSelector((state) => state.allPrograms.filter);
  const programsLoading = useSelector((state) => state.allPrograms.loading);

  const getAllPrograms = useCallback(() => {
    dispatch &&
      getAllProgramsAction({ langId, industryId: parseInt(id) })(dispatch);
  }, [dispatch, langId, id]);

  useEffect(() => {
    getAllPrograms && getAllPrograms();
  }, [getAllPrograms]);
  return (
    <>
      {loading ? (
        <Box sx={{ height: "100vh" }}>
          <Loader />
        </Box>
      ) : (
        <>
          <Container maxWidth={"xl"} sx={{ p: "2em" }}>
            {industryLoading ? (
              <Box sx={{ height: "100vh" }}>
                <Loader />
              </Box>
            ) : (
              <Grid container>
                <Grid
                  item
                  md={5}
                  xs={12}
                  sx={{
                    backgroundColor: "#006464",
                  }}
                >
                  <Box
                    sx={{
                      color: "#FFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      gap: "1em",
                      p: "3em 1em",
                    }}
                  >
                    <Typography variant="h5" fontWeight={600}>
                      {industry && industry?.title}
                    </Typography>
                    <Typography variant="body2">
                      {industry && industry?.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={7} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: 1,
                      height: "450px",
                    }}
                  >
                    <ImageWithLoader
                      component={"img"}
                      src={industry && industry?.image}
                      alt={industry && industry?.title}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            <Typography
              variant="h4"
              fontWeight={700}
              sx={{ textAlign: "center", mt: 5, py: 3 }}
            >
              Pick an App for your business
            </Typography>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
                whiteSpace: "nowrap",
                flexWrap: "wrap",
                "& Button": {
                  bgcolor: "#F6F6F6",
                  color: "#1C1C1C",
                  borderRadius: "5px",
                  p: "0.7em",
                  transition:
                    "background-color 0.5s ease-in-out, color 0.5s ease-in-out",
                  "&:hover": {
                    outline: "1px solid #00CCCC",
                  },
                },
                "& .selected": {
                  bgcolor: "#FF9999",
                  color: "#FAFAFD",
                },
              }}
              ref={ref}
            >
              <Button
                onClick={() => handleFilterClick(0)}
                className={selectedCategory === 0 ? "selected" : ""}
              >
                <Typography variant="button">{t("all")}</Typography>
              </Button>
              {categories?.map((category, index) => (
                <Button
                  key={index}
                  onClick={() => handleFilterClick(category?.id)}
                  className={
                    selectedCategory === category?.id ? "selected" : ""
                  }
                >
                  <Typography variant="button">{category?.type}</Typography>
                </Button>
              ))}
            </Box> */}

            <TabPanel value={value} index={0}>
              {programsLoading ? (
                <Box sx={{ height: "100vh" }}>
                  <Loader />
                </Box>
              ) : programsData?.length <= 0 ? (
                <EmptyPage title={"There is no available apps data now"} />
              ) : (
                <Grid container spacing={2}>
                  {programsData?.map((e, i) => (
                    <ItemCard
                      key={e.id}
                      item={e}
                      index={i}
                      inView={inView}
                      onClick={() => handleClick(e?.id)}
                    />
                  ))}
                </Grid>
              )}
            </TabPanel>
          </Container>
        </>
      )}
    </>
  );
};

export default OurApps;
