import {
  loading,
  error,
  setAppDetailsList,
} from "../../slices/appDetailsSlice";
import { getAppDetailsService } from "../../services/appDetails";

export const getAppDetailsAction =
  ({ langId, appId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getAppDetailsService({ langId, appId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setAppDetailsList(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };
