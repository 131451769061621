import { loading, error, setAboutUsList } from "../../slices/aboutUs";
import { getAboutUsService } from "./../../services/aboutUs";

export const getAboutUsAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getAboutUsService({ langId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setAboutUsList(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };
