import { Box, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const ValueItems = ({ index, item, inView }) => {
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(100%)",
    from: { opacity: 0, transform: "translateY(100%)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });

  return (
    <Grid item key={index} xs={6} md={3}>
      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          backgroundColor: "#F6F8FA",
          padding: "1em",
          borderRadius: "10px",
          ...springProps,
        }}
      >
        <img
          src={item?.image}
          alt={item.title}
          style={{ width: "60px", height: "60px", borderRadius: "5px" }}
        />
        <Typography variant="h6" sx={{ color: "#010C19", fontWeight: "700" }}>
          {item.title}
        </Typography>
      </animated.div>
    </Grid>
  );
};

const Values = ({ title, body, items }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const topAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{ p: "2em", display: "flex", flexDirection: "column", gap: "1em" }}
    >
      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          alignItems: "center",
          ...topAnimation,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "800", color: "#003939" }}>
          {title}
        </Typography>
        <Typography sx={{ fontWeight: "700", color: "#303030" }}>
          {body}
        </Typography>
      </animated.div>

      <Grid container spacing={2}>
        {items?.map((item, index) => (
          <ValueItems key={index} index={index} item={item} inView={inView} />
        ))}
      </Grid>
    </Box>
  );
};

export default Values;
