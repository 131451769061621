import { Box, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const Mission = ({ title, body, images, bgImage }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const topAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const bottomAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const leftAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const rightAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: "2em",
        gap: "2em",
        flexDirection: { xs: "column-reverse", md: "row" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "stretch",
          gap: "0.5em",
        }}
      >
        <animated.div style={{ display: "flex", ...leftAnimation }}>
          <img
            src={images && images[0]?.image}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        </animated.div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "1em",
          }}
        >
          <animated.div style={{ flex: 1, ...topAnimation }}>
            <img
              src={images && images[1]?.image}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </animated.div>

          <animated.div style={{ flex: 1, ...bottomAnimation }}>
            <img
              src={images && images[2]?.image}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </animated.div>
        </Box>
      </Box>
      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          ...rightAnimation,
        }}
      >
        <Typography variant="h3" sx={{ color: "#FFF", fontWeight: "800" }}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#F6F8FA", fontWeight: "700" }}
        >
          {body}
        </Typography>
      </animated.div>
    </Box>
  );
};

export default Mission;
