import { loading, error, setList } from "../../slices/faq";
import {} from "../../services/faq";
import { getFaqService } from "../../services/faq";

export const getFaqAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getFaqService({
        langId,
      });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setList(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };
