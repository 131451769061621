export default function WhyUs4() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.79407 24.0001C9.40575 24.0001 9.09094 23.6853 9.09094 23.297V19.8184C9.09094 19.43 9.40575 19.1152 9.79407 19.1152C10.1824 19.1152 10.4972 19.43 10.4972 19.8184V23.297C10.4972 23.6853 10.1824 24.0001 9.79407 24.0001Z" />
      <path d="M12.6066 24.0001C12.2183 24.0001 11.9034 23.6853 11.9034 23.297V17.9434C11.9034 17.555 12.2183 17.2402 12.6066 17.2402C12.9949 17.2402 13.3097 17.555 13.3097 17.9434V23.297C13.3097 23.6853 12.9949 24.0001 12.6066 24.0001Z" />
      <path d="M4.16907 24.0001C3.78075 24.0001 3.46594 23.6853 3.46594 23.297V19.8184C3.46594 19.43 3.78075 19.1152 4.16907 19.1152C4.55738 19.1152 4.87219 19.43 4.87219 19.8184V23.297C4.87219 23.6853 4.55738 24.0001 4.16907 24.0001Z" />
      <path d="M1.35657 24.0001C0.968255 24.0001 0.653442 23.6853 0.653442 23.297V17.9434C0.653442 17.555 0.968255 17.2402 1.35657 17.2402C1.74488 17.2402 2.05969 17.555 2.05969 17.9434V23.297C2.05969 23.6853 1.74488 24.0001 1.35657 24.0001Z" />
      <path d="M7 24.0001C6.61169 24.0001 6.29688 23.6853 6.29688 23.297V22.1621C6.29688 21.7738 6.61169 21.459 7 21.459C7.38831 21.459 7.70312 21.7738 7.70312 22.1621V23.297C7.70312 23.6853 7.38831 24.0001 7 24.0001Z" />
      <path d="M5.46897 17.2402C5.46752 17.2676 5.46667 17.2951 5.46667 17.3227C5.46667 17.5333 5.52682 17.9167 6.04675 18.8131C6.30939 19.2658 6.56852 19.6418 6.57939 19.6576C6.67483 19.7957 6.832 19.8782 6.99991 19.8782C7.16782 19.8782 7.32499 19.7957 7.42043 19.6576C7.43135 19.6418 7.69043 19.2658 7.95307 18.8131C8.47305 17.9167 8.53314 17.5333 8.53314 17.3227C8.53314 17.295 8.5323 17.2676 8.53085 17.2402H5.46897Z" />
      <path d="M12.772 10.2715L11.6733 15.5601C11.6538 15.6541 11.6279 15.7455 11.5964 15.8342H12.6434C13.0317 15.8342 13.3466 15.5194 13.3466 15.1311V11.9035C13.3465 11.2864 13.131 10.719 12.772 10.2715Z" />
      <path d="M2.33813 15.5627L1.23052 10.2681C0.869911 10.7161 0.653442 11.2848 0.653442 11.9033V15.1309C0.653442 15.5192 0.968255 15.834 1.35657 15.834H2.41435C2.38322 15.7462 2.35758 15.6557 2.33813 15.5627Z" />
      <path d="M10.2175 2.82775C8.94758 1.40186 7.50659 0.210434 7.44589 0.160418C7.18629 -0.0534727 6.81148 -0.0534727 6.55175 0.160418C6.49114 0.21034 5.05203 1.40021 3.78275 2.82484C2.35695 4.42506 1.81179 6.47317 2.28603 8.44571L3.71459 15.2747C3.78275 15.6005 4.07 15.8339 4.40281 15.8339H9.608C9.94118 15.8339 10.2286 15.6 10.2965 15.2738L11.7163 8.43911C12.1864 6.47017 11.6405 4.42543 10.2175 2.82775ZM7.00001 9.67745C5.8369 9.67745 4.89064 8.73118 4.89064 7.56807C4.89064 6.40496 5.8369 5.4587 7.00001 5.4587C8.16312 5.4587 9.10939 6.40496 9.10939 7.56807C9.10939 8.73118 8.16312 9.67745 7.00001 9.67745Z" />
      <path d="M7 8.271C7.38833 8.271 7.70312 7.9562 7.70312 7.56787C7.70312 7.17955 7.38833 6.86475 7 6.86475C6.61167 6.86475 6.29688 7.17955 6.29688 7.56787C6.29688 7.9562 6.61167 8.271 7 8.271Z" />
    </svg>
  );
}
