import {
  Typography,
  Container,
  Button,
  TextField,
  Divider,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CountrySelect from "../../components/CountrySelect";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  name: yup.string().min(4).required("Name is required"),
  countryCode: yup.string().max(20).required("+20"),
  mobile: yup
    .string("Enter your mobile")
    .max(15, "too long")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Must be a valid phone number ex: 1124356930"
    )
    .required("Mobile Number is required"),
  email: yup
    .string("email")
    .min(3, "too short")
    .max(1500, "too long")
    .email("Must be valid email ex; @gamil.com")
    .required("Email is required"),
  password: yup
    .string()
    .required("No password provided.")
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});
const SignUp = () => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      countryCode: "20",
      mobile: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const chooseCountryCode = useCallback(
    (code) => formik.setFieldValue("countryCode", code),
    [formik]
  );
  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          border: "2px solid #F6F6F6",
          borderRadius: 12,
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography fontWeight={700} sx={{ py: 2 }} variant="h4">
          <Button
            sx={{
              display: "inline",
              position: "absolute",
              left: 0,
              color: "text.primary",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBackIosNewIcon />
          </Button>
          Create Your Account
        </Typography>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
            {t("data.name")}
          </Typography>
          <TextField
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            id="name"
            name="name"
            placeholder={t("data.name")}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #F6F6F6",
              },
              mb: 2.5,
            }}
          />

          <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
            {t("data.mobile")}
          </Typography>
          <Box
            sx={{
              width: "100%",
              my: 2,
              border: "2px solid #F6F6F6",
              borderRadius: "6px",
            }}
            display="flex"
          >
            <Box
              sx={{
                width: {
                  lg: "25%",
                  xs: "30%",
                },
              }}
            >
              <CountrySelect
                chooseCode={chooseCountryCode}
                error={
                  formik.touched.countryCode &&
                  Boolean(formik.errors.countryCode)
                }
                helperText={
                  formik.touched.countryCode && formik.errors.countryCode
                }
                noLabel
              />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              light
              sx={{ borderRightWidth: "2px" }}
            />
            <TextField
              fullWidth
              id="mobile"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
              placeholder={t("data.mobile")}
              sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
            />
          </Box>

          <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
            {t("data.email")}
          </Typography>

          <TextField
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            id="email"
            name="email"
            placeholder={t("data.email")}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #F6F6F6",
              },
              mb: 2.5,
            }}
          />
          <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
            {t("data.password")}
          </Typography>

          <TextField
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            id="password"
            name="password"
            type="password"
            placeholder={t("data.password")}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #F6F6F6",
              },
              mb: 2.5,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              my: 1.3,
            }}
          >
            <Typography
              sx={{ width: 0.8, textAlign: "center", color: "text.disable" }}
            >
              By creating an account, you agree to our
              <Button
                variant="text"
                sx={{
                  color: "text.primary",
                  textDecorationLine: "underline",
                  backgroundColor: "transparent",
                  px: 1,
                }}
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Terms of Service
              </Button>
              and have read and understood the
              <Button
                variant="text"
                sx={{
                  color: "text.primary",
                  textDecorationLine: "underline",
                  backgroundColor: "transparent",
                  px: 1,
                }}
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </Button>
            </Typography>

            <Button
              type="submit"
              sx={{
                width: 0.85,
                py: 1.5,
                my: 1.3,
                backgroundColor: "#FCC836",
                borderRadius: "5px",
                whiteSpace: "nowrap",
                color: "#040308",
                "&:hover": {
                  color: "#FCC836",
                  backgroundColor: "#FAFAFD",
                  outline: "3px solid #FCC836",
                },
              }}
            >
              <Typography
                color={"text.primary"}
                variant="button"
                fontWeight={700}
              >
                Create Account
              </Typography>
            </Button>

            <Typography fontWeight={700}>
              Already have an account?
              <Button
                variant="text"
                sx={{
                  color: "text.primary",
                  textDecorationLine: "underline",
                  backgroundColor: "transparent",
                  px: 1,
                }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Log In
              </Button>
            </Typography>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default SignUp;
