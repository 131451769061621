import Header from "./components/header";
import About from "./components/about";
import Generator from "./components/generator";
import Sales from "./components/sales";
import Discover from "./components/discover";
import { Box } from "@mui/material";
import {
  getHomeAction,
  getAllIndustriesAction,
  getIndustryAppsAction,
} from "../../store/actions/home";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import ErrorMessage from "./../../components/ErrorMessage";
import Loader from "./../../components/Loader";
import { useTranslation } from "react-i18next";
import Accounting from "./components/accounting";
import Operation from "./components/operation";
import CRM from "./components/crm";
import Inventory from "./components/inventory";
import HrManagement from "./components/hrManagement";
import WhyUs from "./components/whyUs";
import { Helmet } from "react-helmet";
import { getSeoAction } from "../../store/actions/seo";

const Home = () => {
  const [industryId, setIndustryId] = useState(0);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home.data);
  const homeLoading = useSelector((state) => state.home.loading);

  const homeError = useSelector((state) => state.home.error);
  const industriesData = useSelector((state) => state.home.industries);
  const industryAppsData = useSelector((state) => state.home.industryApps);
  const seo = useSelector((state) => state.seo.data);
  const seoLoading = useSelector((state) => state.seo.loading);

  const { i18n } = useTranslation(["common"]);

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const getHome = useCallback(() => {
    dispatch && getHomeAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getHome && getHome();
  }, [getHome]);

  const getAllIndustries = useCallback(() => {
    dispatch && getAllIndustriesAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getAllIndustries && getAllIndustries();
  }, [getAllIndustries]);

  const getIndustryApps = useCallback(() => {
    dispatch && getIndustryAppsAction({ langId, industryId })(dispatch);
  }, [dispatch, langId, industryId]);

  useEffect(() => {
    getIndustryApps && getIndustryApps();
  }, [getIndustryApps]);

  const getSeoItems = useCallback(() => {
    dispatch && getSeoAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getSeoItems && getSeoItems();
  }, [getSeoItems]);

  const renderMetaTags = () => {
    return seo?.data?.map((tag, index) => {
      return (
        <meta key={index} name={tag?.seoKey?.keyname} content={tag.value} />
      );
    });
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      {homeLoading || seoLoading ? (
        <Box sx={{ height: "100vh" }}>
          <Loader />
        </Box>
      ) : homeError ? (
        <ErrorMessage message={homeError} />
      ) : (
        <>
          <Helmet>
            <title>Mini Company</title>
            {renderMetaTags()}

            {"<!-- Open Graph / Facebook -->"}
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content="https://mini-company.pentavalue.com/"
            />
            <meta property="og:title" content="Mini Company" />
            <meta property="og:image" content={seo?.logo?.[0]?.value} />

            {"<!-- Twitter -->"}
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content="https://mini-company.pentavalue.com/"
            />
            <meta property="twitter:title" content="Mini Company" />
            <meta property="twitter:image" content={seo?.logo?.[0]?.value} />
          </Helmet>

          <Header data={data?.header} />
          <About data={data?.program} />
          <Generator data={data?.ai} />
          <Sales
            data={data?.programDetail?.find((program) => program.id === 2)}
          />

          <Accounting
            data={data?.programDetail?.find((program) => program.id === 3)}
            useCase={data?.useCase?.find((useCase) => useCase.id === 1)}
          />
          <Operation
            data={data?.programDetail?.find((program) => program.id === 6)}
          />
          <CRM
            data={data?.programDetail?.find((program) => program.id === 1)}
            useCase={data?.useCase?.find((useCase) => useCase.id === 2)}
          />
          <Inventory
            data={data?.programDetail?.find((program) => program.id === 4)}
          />
          <HrManagement
            data={data?.programDetail?.find((program) => program.id === 5)}
            useCase={data?.useCase?.find((useCase) => useCase.id === 3)}
          />
          <WhyUs data={data?.choose} />
        </>
      )}
      {homeLoading || seoLoading ? (
        <></>
      ) : (
        <Discover
          data={data?.apps}
          industries={industriesData}
          handleIndustryIdChange={setIndustryId}
          industryAppsData={industryAppsData}
          industryId={industryId}
        />
      )}
    </Box>
  );
};

export default Home;
