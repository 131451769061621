import { loading, error, setList } from "../../slices/seoSlice";
import { getSeoService } from "./../../services/seo";

export const getSeoAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getSeoService({ langId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setList(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };
