export default function WhyUs5() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.497 9.21614 21.3898 6.54715 19.4213 4.57867C17.4528 2.61018 14.7839 1.50298 12 1.5ZM16.2803 16.2803C16.1396 16.4209 15.9489 16.4998 15.75 16.4998C15.5511 16.4998 15.3604 16.4209 15.2198 16.2803L11.4698 12.5303C11.3291 12.3896 11.25 12.1989 11.25 12V6.75C11.25 6.55109 11.329 6.36032 11.4697 6.21967C11.6103 6.07902 11.8011 6 12 6C12.1989 6 12.3897 6.07902 12.5303 6.21967C12.671 6.36032 12.75 6.55109 12.75 6.75V11.6895L16.2803 15.2197C16.4209 15.3604 16.4998 15.5511 16.4998 15.75C16.4998 15.9489 16.4209 16.1396 16.2803 16.2803Z" />
    </svg>
  );
}
