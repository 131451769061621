import { Box } from "@mui/material";
import Features from "./components/features";
import Pricing from "./components/pricing";
import Invoicing from "./components/invoicing";
import Commissions from "./components/commissions";
import Sale from "./components/sale";
import { getAppDetailsAction } from "../../store/actions/appDetails";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import ErrorMessage from "./../../components/ErrorMessage";
import Loader from "./../../components/Loader";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function About() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.appDetails.data);
  const loading = useSelector((state) => state.appDetails.loading);
  const error = useSelector((state) => state.appDetails.error);

  const { i18n } = useTranslation(["common"]);
  const { id } = useParams();

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const getAppDetails = useCallback(() => {
    dispatch && getAppDetailsAction({ langId, appId: id })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getAppDetails && getAppDetails();
  }, [getAppDetails]);

  return (
    <Box sx={{ overflow: "hidden" }}>
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorMessage message={error} />
      ) : (
        <>
          <Features
            title={data?.title}
            description={data?.description}
            features={data?.features}
            photos={data?.photos}
            price={data?.price}
            featuresTitle={data?.pages?.titleAppFeature}
            featuresSubtitle={data?.pages?.subTitleAppFeature}
            featuresBody={data?.pages?.detailsAppFeature}
          />
          <Invoicing details={data?.details?.details1} />
          <Sale details={data?.details?.details2} />
          <Commissions details={data?.details?.details3} />
          <Pricing
            plans={data?.plan}
            title={data?.pages?.titleAppPlan}
            subtitle={data?.pages?.detailsAppPlan}
          />
        </>
      )}
    </Box>
  );
}
