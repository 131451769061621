import { loading, error, setList } from "../../slices/useCasesSlice";
import { getAllUseCasesService } from "./../../services/useCases";

export const getAllUseCasesAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getAllUseCasesService({ langId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setList(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };
