import {
  homeLoading,
  homeError,
  setHomeList,
  setAllIndustriesList,
  setIndustryAppsList,
} from "../../slices/homeSlice";
import {
  getHomeService,
  getAllIndustriesService,
  getIndustryAppsService,
} from "../../services/home";

export const getHomeAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(homeLoading());
    try {
      const response = await getHomeService({ langId });
      if (response?.error) {
        dispatch(homeError(response.error));
        return;
      } else {
        dispatch(setHomeList(response));
        return;
      }
    } catch (error) {
      dispatch(homeError(error.message));
      return;
    }
  };

export const getAllIndustriesAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(homeLoading());
    try {
      const response = await getAllIndustriesService({ langId });
      if (response?.error) {
        dispatch(homeError(response.error));
        return;
      } else {
        dispatch(setAllIndustriesList(response));
        return;
      }
    } catch (error) {
      dispatch(homeError(error.message));
      return;
    }
  };

export const getIndustryAppsAction =
  ({ langId, industryId }) =>
  async (dispatch) => {
    dispatch(homeLoading());
    try {
      const response = await getIndustryAppsService({ langId, industryId });
      if (response?.error) {
        dispatch(homeError(response.error));
        return;
      } else {
        dispatch(setIndustryAppsList(response));
        return;
      }
    } catch (error) {
      dispatch(homeError(error.message));
      return;
    }
  };
