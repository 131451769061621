import { Box } from "@mui/material";
import Header from "./Header";
import Mission from "./Mission";
import Values from "./Values";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { getAboutUsAction } from "./../../store/actions/aboutUs";

const AboutUs = () => {
  const { i18n } = useTranslation(["common"]);
  const dispatch = useDispatch();

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const data = useSelector((s) => s.aboutUs.data);
  const loading = useSelector((s) => s.aboutUs.loading);

  const getAboutUs = useCallback(() => {
    dispatch && getAboutUsAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getAboutUs && getAboutUs();
  }, [getAboutUs]);

  return (
    <>
      {loading ? (
        <Box sx={{ height: "100vh" }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ overflow: "hidden" }}>
          <Header
            title={data?.headertitle}
            body={data?.headerBody}
            image={data?.headerimage}
          />
          <Mission
            title={data?.title}
            body={data?.body}
            images={data?.photos}
            bgImage={data?.BGImage}
          />
          <Values
            title={data?.footertitle}
            body={data?.footerBody}
            items={data?.aboutUsItem}
          />
        </Box>
      )}
    </>
  );
};

export default AboutUs;
