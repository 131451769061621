import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { loading, error, setList } = faqSlice.actions;

export default faqSlice.reducer;
