import { Box, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import WhyUs1 from "./../../../svgs/whyUs1";
import WhyUs2 from "./../../../svgs/whyUs2";
import WhyUs3 from "./../../../svgs/whyUs3";
import WhyUs4 from "./../../../svgs/whyUs4";
import WhyUs5 from "./../../../svgs/whyUs5";
import WhyUs6 from "./../../../svgs/whyUs6";

const WhyUsItems = ({ index, item, inView }) => {
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(100%)",
    from: { opacity: 0, transform: "translateY(100%)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });

  const renderWhyUsSvg = (index) => {
    switch (index) {
      case 1:
        return <WhyUs1 />;
      case 2:
        return <WhyUs2 />;
      case 3:
        return <WhyUs3 />;
      case 4:
        return <WhyUs4 />;
      case 5:
        return <WhyUs5 />;
      case 6:
        return <WhyUs6 />;
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex" }}>
      <animated.div style={{ ...springProps, display: "flex", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            p: "1em",
            borderRadius: "20px",
            bgcolor: "#FFF",
            boxShadow: "3px 3px 20px 0 rgba(166, 166, 166, 0.18)",
            transition: "0.3s all",
            "& > *": {
              "& > *": {
                fill: "#003939",
                transition: "0.5s all",
              },
            },
            "&:hover": {
              bgcolor: "#00CCCC",
              "& .inner-box": {
                transition: "0.3s all",
                bgcolor: "#08B5B5",
                "& > *": {
                  fill: "#FFF",
                },
              },
            },
          }}
        >
          <Box
            className="inner-box"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "0.5em",
              bgcolor: "#F2F2F2",
              borderRadius: "50%",
              width: "fit-content",
            }}
          >
            {renderWhyUsSvg(index + 1)}
          </Box>
          <Typography variant="h6">{item.title}</Typography>
          <Typography variant="body1" sx={{ color: "#263238" }}>
            {item.description}
          </Typography>
        </Box>
      </animated.div>
    </Grid>
  );
};

export default function WhyUs({ data }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const topAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{ p: "2em", display: "flex", flexDirection: "column", gap: "2em" }}
    >
      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "1em",
          ...topAnimation,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2A8282" }}>
          {data?.chooseTitle}
        </Typography>
        <Typography variant="h4" sx={{ color: "#001D1D" }}>
          {data?.chooseSubTitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#303030", fontWeight: "700" }}
        >
          {data?.chooseBody}
        </Typography>
      </animated.div>
      <Grid container spacing={2}>
        {data?.chooseDetail?.map((item, index) => (
          <WhyUsItems key={item.id} item={item} index={index} inView={inView} />
        ))}
      </Grid>
    </Box>
  );
}
