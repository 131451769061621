import { Typography, Box } from "@mui/material";
import { emptyPage } from "../../Assets/index";
const EmptyPage = ({ title }) => {
  return (
    <>
      <Box
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          height: 500,

          borderRadius: "24px",
          backgroundColor: "#F6F8FA",
        }}
      >
        <Box sx={{ my: 4 }}>
          <img src={emptyPage} alt="package" />
        </Box>
        <Box>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ width: 350, textAlign: "center" }}
            color="text.secondary"
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default EmptyPage;
