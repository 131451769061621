import { createSlice } from "@reduxjs/toolkit";
import { filter } from "stylis";

const initialState = {
  data: [],
  filter: [],
  program: {},
  loading: false,
  error: null,
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    setListIndustry(state, action) {
      state.loading = false;
      state.filter = action.payload;
    },
    setProgramDetails(state, action) {
      state.loading = false;
      state.program = action.payload;
    },
  },
});

export const { loading, error, setList, setProgramDetails, setListIndustry } =
  programsSlice.actions;

export default programsSlice.reducer;
