import { Box, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const Header = ({ title, body, image }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const leftAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const rightAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "1em",
        p: "2em 2em 0 2em",
        backgroundImage: `url(./images/aboutUsBg1.svg)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          gap: "1em",
          flex: 1,
          ...leftAnimation,
        }}
      >
        <Typography variant="h3" sx={{ color: "#003939", fontWeight: "700" }}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#303030", fontWeight: "700" }}
        >
          {body}
        </Typography>
      </animated.div>

      <animated.div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "end",
          ...rightAnimation,
        }}
      >
        <img src={image} alt="" style={{ width: "100%", maxWidth: "700px" }} />
      </animated.div>
    </Box>
  );
};

export default Header;
