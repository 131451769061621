import { Box, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const AboutItem = ({ index, item, inView }) => {
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(50px)",
    from: { opacity: 0, transform: "translateY(50px)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <animated.div style={springProps}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5em",
            "> *": {
              textAlign: "center",
            },
            mt: "1em",
          }}
        >
          <Box
            sx={{
              p: "1em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: { xs: "30px", md: "40px", xl: "50px" },
              height: { xs: "30px", md: "40px", xl: "50px" },
            }}
          >
            <img src={item.icon} alt={item.title} />
          </Box>

          <Typography variant="h6">{item.title}</Typography>
          <Typography variant="body1">{item.description}</Typography>
        </Box>
      </animated.div>
    </Grid>
  );
};

export default function About({ data }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
        p: "2em",
        position: "relative",
        overflow: "hidden",
        mt: "4em",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          "> *": {
            flex: 1,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
      >
        <img src="./images/aboutLeftBg.png" alt="" />
        <img src="./images/aboutCenterBg.png" alt="" />
        <img src="./images/aboutRightBg.png" alt="" />
      </Box>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          color: "#2A8282",
        }}
      >
        {data?.listTitle}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          color: "#001D1D",
        }}
      >
        {data?.listBody}
      </Typography>
      <Grid container spacing={4}>
        {data?.program?.slice(0,6)?.map((item, index) => (
          <AboutItem key={index} index={index} item={item} inView={inView} />
        ))}
      </Grid>
    </Box>
  );
}
