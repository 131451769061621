import { Box, Typography } from "@mui/material";
import { comingSoon } from "../Assets";

const ComingSoon = () => {
  return (
    <>
      <Box
        sx={{
          width: 1,
          height: "75vh",
          backgroundColor: "#F6F8FA",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <img src={comingSoon} alt="comingSoon" />
          <Typography variant="body1" fontWeight={700} textAlign={"center"}>
            Coming soon, stay close
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ComingSoon;
