import { loading, error, setPrivacyPolicyList } from "../../slices/privacy";
import { getPrivacyPolicyService } from "./../../services/privacy";

export const getPrivacyPolicyAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getPrivacyPolicyService({ langId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setPrivacyPolicyList(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };
