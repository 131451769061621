import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const InventoryItems = ({ index, item, inView, id }) => {
  const [showButton, setShowButton] = useState(false);
  const navigate = useNavigate();

  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(100%)",
    from: { opacity: 0, transform: "translateY(100%)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
      <animated.div
        style={{
          display: "flex",
          gap: "1em",
          alignItems: "start",
          flexDirection: "column",
          borderRadius: "20px",
          padding: "2em",
          width: "100%",
          outline: "1px solid #F7F7F7",
          backgroundColor: "#FFF",
          boxShadow: "1px 15px 23px 0 rgba(229, 229, 229, 0.16)",
          ...springProps,
        }}
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
      >
        <img
          src={item.icon}
          alt={`Inventory ${index + 1}`}
          style={{
            width: "80px",
            height: "80px",
            borderRadius: "10px",
          }}
        />
        <Typography variant="h6">{item.title}</Typography>
        {item?.linkname && (
          <Collapse in={showButton} timeout={500} unmountOnExit>
            <Button
              onClick={() => {
                navigate(`/program/${id}`);
              }}
              // component={Link}
              // to={item.link}
              sx={{
                p: "0.3em 1.5em",
                borderRadius: "5px",
                bgcolor: "#FCC836",
                transition: "background-color 0.5s ease-in-out",
                "&:hover": {
                  bgcolor: "#FFF",
                  outline: "1px solid #FCC836",
                  "& > *": {
                    color: "#FCC836",
                  },
                },
              }}
            >
              <Typography
                variant="button"
                sx={{
                  color: "#040308",
                  transition: "color 0.5s ease-in-out",
                }}
              >
                {item?.linkname}
              </Typography>
            </Button>
          </Collapse>
        )}
      </animated.div>
    </Grid>
  );
};

export default function Inventory({ data }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const topAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        p: "2em",
        position: "relative",
      }}
    >
      <img
        src="/images/blueCircle.svg"
        alt="Blue Circle Background"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: -1,
          transform: "translate(0, 50%)",
          width: "150px",
        }}
      />
      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          width: "80%",
          margin: "0 auto",
          ...topAnimation,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2A8282" }}>
          {data?.detailTitle}
        </Typography>
        <Typography variant="h4" sx={{ color: "#001D1D" }}>
          {data?.detailSubTitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#303030",
            textAlign: "center",
          }}
        >
          {data?.detailDescription}
        </Typography>
      </animated.div>
      <Grid container spacing={2}>
        {data?.item?.map((item, index) => (
          <InventoryItems
            key={index}
            item={item}
            index={index}
            inView={inView}
            id={data?.id}
          />
        ))}
      </Grid>
    </Box>
  );
}
