import { Box, Button, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Header({ data }) {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const imageProps = useSpring({
    config: { tension: 20, friction: 0 },
    from: { transform: "translateY(0px)" },
    to: async (next, cancel) => {
      while (true) {
        await next({ transform: "translateY(-2px)" });
        await next({ transform: "translateY(2px)" });
      }
    },
  });

  return (
    <Box
      sx={{
        p: "8em 2em 2em 2em",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <img
        src="./images/headerBg.png"
        alt="Header Background"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          height: "auto",
          width: "70%",
          maxHeight: "100%",
        }}
      />
      <img
        src="./images/graphBg.svg"
        alt="Graph Background"
        style={{
          position: "absolute",
          top: "50%",
          left: 0,
          width: "70%",
          zIndex: -2,
          opacity: "56%",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          gap: "1em",
          flex: 1,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#242331",
          }}
        >
          {data?.headertitle}
        </Typography>
        <Typography variant="body1" sx={{ color: "#363636" }}>
          {data?.headerBody}
        </Typography>
        <Button
          onClick={() => {
            navigate("/sign-up");
          }}
          sx={{
            bgcolor: "#FCC836",
            borderRadius: "5px",
            color: "#040308",
            p: "0.5em 1em",
            transition: "background-color 0.5s ease-in-out",
            "&:hover": {
              bgcolor: "#FFF",
              outline: "1px solid #FCC836",
              "& > *": {
                color: "#FCC836",
              },
            },
          }}
        >
          <Typography
            variant="button"
            sx={{ transition: "color 0.5s ease-in-out" }}
          >
            {t("get_started_for_free")}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          position: "relative",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        <animated.div
          style={{
            zIndex: 3,
            ...imageProps,
          }}
        >
          <img
            src={data?.headerImage}
            alt="Header 1"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "300px",
            }}
          />
        </animated.div>

        <animated.div
          style={{
            zIndex: 3,
            ...imageProps,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={data?.headerImage2}
            alt="Header 2"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "300px",
            }}
          />
        </animated.div>

        <Box
          sx={{
            position: "absolute",
            top: { xs: 0, md: "-20%", lg: "-30%" },
            right: "-15%",
            zIndex: -1,
            width: { xs: "110%", xl: "100%" },
            height: "auto",
          }}
        >
          <img
            src={data?.headerCover}
            alt="Orbit"
            style={{
              width: "100%",
              height: "auto",
              zIndex: -1,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
