import axios from "axios";

export const getAllCategoriesService = async ({ langId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/category/all`,
      {
        langId: langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIndustryAppsService = async ({
  langId,
  categoryId,
  industryId,
}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/industry/filter`,
      {
        langId,
        categoryId,
        industryId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
