import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function UseCases({ data, loading, error }) {
  return (
    <Box
      sx={{
        p: "2em 4em",
        backgroundImage: "url('/images/useCaseShapesBg.svg')",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container spacing={2}>
        {data?.map((item, index) => (
          <Grid item key={index} xs={4} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "20px",
                outline: "1px solid #F5F5FB",
                width: "100%",
                bgcolor: "#FFF",
              }}
            >
              {loading ? (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ bgcolor: "grey.900" }}
                >
                  <img
                    src={item.image}
                    alt={`Case ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "250px",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                      objectFit: "cover",
                    }}
                  />
                </Skeleton>
              ) : (
                <img
                  src={item.image}
                  alt={`Case ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "250px",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    objectFit: "cover",
                  }}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: "1em",
                  justifyContent: "space-between",
                  alignItems: "start",
                  gap: "1em",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                    }}
                  >
                    <Typography variant="h6">
                      {loading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ bgcolor: "grey.900" }}
                        />
                      ) : (
                        item.title
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "700", color: "#706F6F" }}
                    >
                      {loading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ bgcolor: "grey.900" }}
                        />
                      ) : (
                        item.description
                      )}
                    </Typography>
                  </Box>
                </Box>
                {data?.linkname && (
                  <Button
                    component={Link}
                    to={data?.link}
                    sx={{ bgcolor: "#FCC836" }}
                  >
                    <Typography variant="button">{data?.linkname}</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
