import { Box, CardMedia, Container, Grid } from "@mui/material";
import { contactUs } from "../../Assets";
import ContactUsForm from "./ContactUsForm";
import ImageWithLoader from "../../components/ImageWithLoader";

const ContactUs = () => {
  return (
    <Container>
      <Grid container sx={{ mt: 4.5 }} spacing={2}>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              height: "650px",
              width: 1,
              borderRadius: "16px",
              overflow: "hidden",
            }}
          >
            <ImageWithLoader
              component={"img"}
              src={contactUs}
              alt={"contact Us"}
              style={{ height: "650px", width: "100%", objectFit: "cover" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: 1 }}>
            <ContactUsForm />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactUs;
