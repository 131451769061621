import { Container, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../components/Loader";
import Parser from "html-react-parser";
import { getPrivacyPolicyAction } from "./../../store/actions/privacy";

const Privacy = () => {
  const { i18n } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const Privacy = useSelector((s) => s.privacyPolicy.data);
  const loading = useSelector((s) => s.privacyPolicy.loading);

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const getPrivacyPolicy = useCallback(() => {
    dispatch && getPrivacyPolicyAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getPrivacyPolicy && getPrivacyPolicy();
  }, [getPrivacyPolicy]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {Privacy?.slice(0, 1)?.map((e, i) => (
            <>
              <Typography
                variant="h4"
                // color={"text.darkGreen"}
                fontWeight={700}
                sx={{ py: 3 }}
              >
                {e?.title}
              </Typography>
              <Typography variant="body1">{Parser(e?.detail)}</Typography>
            </>
          ))}
          {Privacy?.slice(1)?.map((e, i) => (
            <>
              <Typography
                variant="h3"
                color={"secondary.olive"}
                fontWeight={700}
                sx={{ py: 3 }}
              >
                {e?.title}
              </Typography>
              <Typography variant="body1">{Parser(e?.detail)}</Typography>
            </>
          ))}
        </Container>
      )}
    </>
  );
};

export default Privacy;
