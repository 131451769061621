import { Box, Button, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const SalesItem = ({ item, index, inView, id }) => {
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(50px)",
    from: { opacity: 0, transform: "translateY(50px)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <animated.div style={springProps}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2em",
            p: "2em",
            backgroundImage: `url('${item.BGimage}')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            "& > *:not(.MuiButton-root)": {
              color: "#FFF",
            },
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
                height: "40px",
              }}
            >
              <img
                src={item.icon}
                alt={`Sales ${index + 1}`}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>

            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "0.5em",
              }}
            >
              &bull;
              <Typography variant="body1" sx={{ color: "#FFF" }}>
                {item?.title}
              </Typography>
            </Box>

            <Button
              onClick={() => {
                navigate(`/program/${id}`);
              }}
              size="small"
              sx={{
                bgcolor: "#FCC836",
                color: "#040308",
                transition: "0.5s all",
                "&:hover": {
                  bgcolor: "#FFF",
                  "& > *": {
                    color: "#FCC836",
                  },
                },
                width: "fit-content",
                p: "0.3em 1.5em",
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="button"
                sx={{ transition: "color 0.5s ease-in-out" }}
              >
                Go Now
              </Typography>
            </Button>
          </Box>
        </Box>
      </animated.div>
    </Grid>
  );
};

export default function Sales({ data }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Box
      ref={ref}
      sx={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        p: "2em",
        gap: "1em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#2A8282",
            textAlign: "center",
          }}
        >
          {data?.detailTitle}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: "#001D1D",
            textAlign: "center",
          }}
        >
          {data?.detailSubTitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#303030",
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          {data?.detailDescription}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {data?.item?.map((item, index) => (
          <SalesItem
            key={index}
            item={item}
            index={index}
            inView={inView}
            id={data?.id}
          />
        ))}
      </Grid>
    </Box>
  );
}
