import { Box, Container, Grid, Typography } from "@mui/material";
import { FAQImage } from "../../Assets";

import { CustomAccordion } from "../../components/CustomAccrodion";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import { getFaqAction } from "../../store/actions/faq";

const FaqPage = () => {
  const { i18n } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const FAQ = useSelector((s) => s.faq.data);
  const loading = useSelector((s) => s.faq.loading);

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const getFAQ = useCallback(() => {
    dispatch && getFaqAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getFAQ && getFAQ();
  }, [getFAQ]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <Grid
            container
            columnSpacing={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item md={8} xs={12}>
              <Typography variant="h1" fontWeight={700} sx={{ py: 3 }}>
                {FAQ?.title}
              </Typography>
              <Typography fontWeight={700} sx={{ py: 3, pr: 9 }}>
                {FAQ?.details}
              </Typography>
            </Grid>

            <Grid item md={4} xs={12}>
              <img src={FAQImage} alt="FAQ" />
            </Grid>
          </Grid>
          <Box backgroundColor={"primary.companyBack"} sx={{ p: 2 }}>
            <Typography
              variant="h1"
              fontWeight={700}
              sx={{ py: 3, textAlign: "center", color: "text.primary" }}
            >
              Frequently Asked Questions
            </Typography>
            {FAQ?.questions?.map((e, i) => (
              <Box
                sx={{
                  backgroundColor: "primary.white",
                  m: 3,
                }}
              >
                <CustomAccordion title={e?.question} children={e?.answer} />
              </Box>
            ))}
          </Box>
        </Container>
      )}
    </>
  );
};

export default FaqPage;
