import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const AccountingItems = ({ index, item, inView, id }) => {
  const [showButton, setShowButton] = useState(false);
  const navigate = useNavigate();

  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(100%)",
    from: { opacity: 0, transform: "translateY(100%)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <Grid item xs={12} sm={6} lg={3} sx={{ display: "flex" }}>
      <animated.div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          alignItems: "start",
          backgroundColor: "#F6F6F6",
          borderRadius: "10px",
          padding: "1em",
          width: "100%",
          ...springProps,
        }}
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
      >
        <img
          src={item.icon}
          alt={`accounting ${index + 1}`}
          style={{ width: "50px", height: "50px" }}
        />
        <Typography variant="h6">{item.title}</Typography>
        {item?.linkname && (
          <Collapse in={showButton} timeout={500} unmountOnExit>
            <Button
              onClick={() => {
                navigate(`/program/${id}`);
              }}
              // component={Link}
              // to={item?.link}
              sx={{
                p: "0.3em 1.5em",
                borderRadius: "5px",
                bgcolor: "#FCC836",
                transition: "background-color 0.5s ease-in-out",
                "&:hover": {
                  bgcolor: "#FFF",
                  outline: "1px solid #FCC836",
                  "& > *": {
                    color: "#FCC836",
                  },
                },
              }}
            >
              <Typography
                variant="button"
                sx={{
                  color: "#040308",
                  transition: "color 0.5s ease-in-out",
                }}
              >
                {item?.linkname}
              </Typography>
            </Button>
          </Collapse>
        )}
      </animated.div>
    </Grid>
  );
};

export default function Accounting({ data, useCase }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const navigate = useNavigate();

  const topAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const bottomAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{
        p: "2em",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "4em",
        mb: "4em",
      }}
    >
      <img
        src="/images/purpleCircle.svg"
        alt="Purple Circle Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          transform: "translate(0, -50%)",
          width: "150px",
        }}
      />

      <img
        src="/images/greenCircle.svg"
        alt="Green Circle Background"
        style={{
          position: "absolute",
          top: "50%",
          right: 0,
          zIndex: -1,
          transform: "translate(0, -50%)",
          width: "50px",
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <animated.div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            width: "80%",
            margin: "0 auto",
            ...topAnimation,
          }}
        >
          <Typography variant="h6" sx={{ color: "#2A8282" }}>
            {data?.detailTitle}
          </Typography>
          <Typography variant="h4" sx={{ color: "#001D1D" }}>
            {data?.detailSubTitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#303030",
              textAlign: "center",
              fontWeight: "700",
            }}
          >
            {data?.detailDescription}
          </Typography>
        </animated.div>
        <Grid container spacing={2}>
          {data?.item?.map((item, index) => (
            <AccountingItems
              key={index}
              item={item}
              index={index}
              inView={inView}
              id={data?.id}
            />
          ))}
        </Grid>
      </Box>

      <animated.div
        style={{
          ...bottomAnimation,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${useCase?.BGimage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            padding: "2em",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "20px",
            alignItems: "center",
            gap: "2em",
            flexDirection: { xs: "column", md: "row" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "1em",
              width: { xs: "100%", md: "80%" },
            }}
          >
            <Typography variant="h5" sx={{ color: "#FFF" }}>
              {useCase?.title}
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFF" }}>
              {useCase?.description}
            </Typography>
            {useCase?.linkname && (
              <Button
                onClick={() => {
                  navigate("/sign-up");
                }}
                // component={Link}
                // to={useCase?.link}
                sx={{
                  p: "0.3em 1.5em",
                  borderRadius: "5px",
                  bgcolor: "#FCC836",
                  transition: "background-color 0.5s ease-in-out",
                  "&:hover": {
                    bgcolor: "#FFF",
                    "& > *": {
                      color: "#FCC836",
                    },
                  },
                }}
              >
                <Typography
                  variant="button"
                  sx={{
                    textTransform: "none",
                    color: "#040308",
                    transition: "color 0.5s ease-in-out",
                  }}
                >
                  {useCase?.linkname}
                </Typography>
              </Button>
            )}
          </Box>
          <Box sx={{ position: { xs: "relative", md: "absolute", right: 0 } }}>
            <img
              src={useCase?.coverImage}
              alt="Use Case Background"
              style={{ maxWidth: "450px", height: "auto", maxHeight: "300px" }}
            />
          </Box>
        </Box>
      </animated.div>
    </Box>
  );
}
