import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/home/index";
import AppDetails from "./pages/details/index";
import Industries from "./pages/Industries/index";
import "./theme/fonts.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Fragment, Suspense, useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import getTheme from "./theme/index";
import { CacheProvider } from "@emotion/react";
import UseCase from "./pages/useCase";
import Loader from "./components/Loader";
import { Helmet } from "react-helmet";
import SignUp from "./pages/Login/Sign";
import AboutUs from "./pages/AboutUs/index";
import FAQ from "./pages/FAQ/index";
import ContactUs from "./pages/ContactUs/index";
import Privacy from "./pages/Privacy";
import LoginForm from "./pages/Login/LoginForm";
import ComingSoon from "./components/ComingSoon";
import ProgramDetails from "./pages/program/ProgramDetails";

const ScrollToTop = () => {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  // Scroll to top when a new page is loaded
  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);
};

function App() {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "ar" ? "rtl" : "ltr";
  moment.locale(i18n.language);
  document.body.setAttribute("dir", isRTL);

  const theme = useMemo(() => {
    return createTheme({ ...getTheme(isRTL), direction: isRTL });
  }, [isRTL]);

  let plugins = [prefixer];
  if (isRTL === "rtl") {
    plugins.push(rtlPlugin);
  }

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: plugins,
  });

  return (
    <Fragment>
      <Helmet>
        {"<!-- Primary Meta Tags -->"}
        <title>Mini Company</title>
        <meta name="title" content="Mini Company" />
        <meta
          name="description"
          content="An ERP System to Run Your Entire Business. CRM, Sales, Accounting, Inventory, Human Resource, Operations"
        />

        {"<!-- Open Graph / Facebook -->"}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://mini-company.pentavalue.com/"
        />
        <meta property="og:title" content="Mini Company" />
        <meta
          property="og:description"
          content="An ERP System to Run Your Entire Business. CRM, Sales, Accounting, Inventory, Human Resource, Operations"
        />
        <meta
          property="og:image"
          content="https://metatags.io/images/meta-tags.png"
        />

        {"<!-- Twitter -->"}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://mini-company.pentavalue.com/"
        />
        <meta property="twitter:title" content="Mini Company" />
        <meta
          property="twitter:description"
          content="An ERP System to Run Your Entire Business. CRM, Sales, Accounting, Inventory, Human Resource, Operations"
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/images/meta-tags.png"
        />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <Router>
                <ScrollToTop />
                <Navbar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="app/:id" element={<AppDetails />} />
                  <Route path="program/:id" element={<ProgramDetails />} />
                  <Route path="use-case" element={<UseCase />} />
                  <Route path="/industries/:id" element={<Industries />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/privacy-policy" element={<Privacy />} />
                  <Route path="/login" element={<LoginForm />} />
                  <Route path="/Generator" element={<ComingSoon />} />
                </Routes>
                <Footer />
              </Router>
            </Provider>
          </ThemeProvider>
        </CacheProvider>
      </Suspense>
    </Fragment>
  );
}

export default App;
