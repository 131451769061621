export default function WhyUs6() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.7231 7.90445C14.4317 7.36349 14.6379 6.46922 14.6951 5.94586C14.7356 5.57562 14.8279 5.19165 14.9692 4.80456L15.0754 4.51416C13.6033 4.53204 12.4823 4.93411 11.7258 5.70524C12.836 6.28191 13.4193 7.15436 13.7231 7.90445Z" />
      <path d="M11.9998 15.8223C11.4948 15.936 8.42176 16.7977 7.97021 20.9999H16.0298C15.5784 16.7997 12.5081 15.9367 11.9998 15.8223Z" />
      <path d="M10.4241 7.44074C10.241 7.34442 10.1703 7.11774 10.2665 6.93463C10.3628 6.75134 10.5897 6.68085 10.7724 6.77661C10.842 6.81307 12.2929 7.58427 13.2504 8.92357C13.0178 7.74588 12.1417 5.8693 8.92468 5.82666L9.0307 6.11688C9.17224 6.50379 9.26453 6.88794 9.30481 7.25854C9.36066 7.76941 9.56482 8.70783 10.3055 9.2489C10.8934 9.67826 11.7299 9.79 12.7903 9.58291C11.9548 8.25743 10.4404 7.44929 10.4241 7.44074Z" />
      <path d="M18.7054 21H18.6618C18.7768 19.4704 18.7984 14.2725 14.1307 12C18.7984 9.7275 18.7768 4.52957 18.6618 3H18.7054C19.3258 3 19.8304 2.49536 19.8304 1.875C19.8304 1.25464 19.3258 0.75 18.7054 0.75H5.29443C4.67407 0.75 4.16943 1.25464 4.16943 1.875C4.16943 2.49536 4.67407 3 5.29443 3H5.33811C5.22312 4.52957 5.20149 9.7275 9.86914 12C5.20149 14.2725 5.22312 19.4704 5.33811 21H5.29443C4.67407 21 4.16943 21.5046 4.16943 22.125C4.16943 22.7454 4.67407 23.25 5.29443 23.25H18.7054C19.3258 23.25 19.8304 22.7454 19.8304 22.125C19.8304 21.5046 19.3258 21 18.7054 21ZM9.8631 9.85474C8.8899 9.14374 8.62842 7.97388 8.55921 7.33978C8.52552 7.02942 8.44714 6.70477 8.3263 6.37464L8.03937 5.5893C7.99835 5.47705 8.01337 5.35199 8.07965 5.25256C8.14594 5.15314 8.25562 5.09125 8.375 5.08594C9.43482 5.03998 10.2929 5.1665 10.9943 5.39742C11.9846 4.23081 13.5358 3.67928 15.6249 3.77362C15.7443 3.77893 15.8539 3.84082 15.9202 3.94025C15.9865 4.03968 16.0015 4.16474 15.9605 4.27698L15.6738 5.06214C15.5529 5.39246 15.4745 5.71729 15.4407 6.02765C15.364 6.72895 15.0667 7.96882 13.9591 8.65373C14.1822 9.37727 13.7382 10.1616 12.986 10.3101C12.5751 10.3934 12.1889 10.4352 11.8285 10.4352C11.0503 10.4352 10.3923 10.2413 9.8631 9.85474ZM16.7118 21.6315C16.6409 21.7072 16.5419 21.75 16.4382 21.75H7.56165C7.45801 21.75 7.35895 21.7072 7.28809 21.6315C7.21723 21.5559 7.18079 21.4543 7.18738 21.3508C7.48351 16.7654 10.6175 15.4543 11.6249 15.1501V12.8168C11.6249 12.6097 11.7929 12.4418 11.9999 12.4418C12.207 12.4418 12.3749 12.6097 12.3749 12.8168V15.1501C13.3824 15.4543 16.5164 16.7654 16.8125 21.3508C16.8191 21.4543 16.7827 21.5559 16.7118 21.6315Z" />
    </svg>
  );
}
