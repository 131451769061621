import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const items = [
    {
      title: "links",
      content: [
        { title: "modules", link: null },
        { title: "industries", link: null },
        { title: "pricing", link: null },
        { title: "contact_us", link: "/contact-us" },
        { title: "faq", link: "/faq" },
        { title: "Privacy Policy", link: "/privacy-policy" },
      ],
    },

    {
      title: "programs",
      content: [
        { title: "Sales management software", link: null },
        { title: "Inventory management software", link: null },
        { title: "Accounting software", link: null },
        { title: "Operations management software", link: null },
        { title: "Clients relationship management software", link: null },
        { title: "Human resources management software", link: null },
      ],
    },

    {
      title: "modules",
      content: [
        { title: "sign_up", link: "/sign-up" },
        { title: "login", link: "/login" },
        { title: "pricing", link: null },
        { title: "contact_us", link: "/contact-us" },
      ],
    },

    {
      title: "industries",
      content: [
        { title: "Construction and real estate investment" },
        { title: "Maintenance center and workshop" },
        { title: "Translation center management" },
        { title: "Program for managing educational centers" },
        { title: "School and nursery management program" },
        { title: "Gym, fitness centers and health clubs" },
        { title: "Clinics and medical centers management" },
      ],
    },
  ];

  const socials = [
    "/images/facebookLogo.svg",
    "/images/twitterLogo.svg",
    "/images/instagramLogo.svg",
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundImage: "url('/images/footerBg.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          p: "3em 4em",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "2em",
          }}
        >
          <img
            onClick={() => navigate("/")}
            src="/images/miniLogoFooter.svg"
            alt="Website Logo"
            style={{ width: "200px", cursor: "pointer" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
          >
            {socials?.map((item, index) => (
              <IconButton
                key={index}
                sx={{
                  bgcolor: "#FFF",
                  transition: "0.5s all",
                  width: "40px",
                  height: "40px",

                  "&:hover": {
                    bgcolor: "#FFF",
                    transform: "scale(1.2)",
                  },
                }}
              >
                <img
                  src={item}
                  alt={`Social ${index + 1}`}
                  style={{ height: "20px" }}
                />
              </IconButton>
            ))}
          </Box>
          <img src="/images/footerCards.png" alt="Payment Methods" />
        </Grid>
        {items?.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={2}
            lg={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: "1em",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#FFF",
              }}
            >
              {t(`${item.title}`)}
            </Typography>
            <List
              sx={{
                p: 0,
                "& .MuiListItem-root": {
                  padding: "0.3em 0",
                },
                "& Button": {
                  p: 0,
                  color: "#FFF",
                  transition: "0.5s all",
                  justifyContent: "start",
                  "&:hover": {
                    bgcolor: "transparent",
                    color: "#FCC836",
                  },
                },
              }}
            >
              {item?.content?.map((item, index) => (
                <ListItem key={index}>
                  <Button
                    component={Link}
                    to={item?.link}
                    size="small"
                    variant="text"
                    sx={{
                      backgroundColor: "transparent",
                      color: "#FFF",
                      padding: 0,
                      textAlign: "start",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      "&:hover": {
                        color: "#FCC836",
                      },
                    }}
                  >
                    <Typography variant="body2" sx={{ textAlign: "start" }}>
                      {t(`${item?.title}`)}
                    </Typography>
                  </Button>
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ bgcolor: "#006464", p: "0.5em" }}>
        <Typography sx={{ color: "#FFF", textAlign: "center" }}>
          {t("Copyright PentaValue© All rights reserved")}
        </Typography>
      </Box>
    </Box>
  );
}
