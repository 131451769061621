import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setPrivacyPolicyList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { loading, error, setPrivacyPolicyList } =
  privacyPolicySlice.actions;

export default privacyPolicySlice.reducer;
