import axios from "axios";

export const getHomeService = async ({ langId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/home/view`,
      {
        langId: langId,
        useCase: 3,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllIndustriesService = async ({ langId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/industry/view`,
      {
        langId: langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIndustryAppsService = async ({ langId, industryId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/industry/filter/${industryId}`,
      {
        langId: langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIndustryService = async ({ langId, industryId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/industry/view/${industryId}`,
      {
        langId: langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
