import { Box, Button, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate } from "react-router-dom";

const HrItems = ({ index, item, inView }) => {
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateX(0)" : "translateX(100%)",
    from: { opacity: 0, transform: "translateX(100%)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <Grid item xs={12}>
      <animated.div
        style={{
          display: "flex",
          gap: "0.5em",
          alignItems: "center",
          backgroundColor: "#E6FFE7",
          padding: "1em",
          borderRadius: "10px",
          ...springProps,
        }}
      >
        <img
          src={item.icon}
          alt={`HR ${index + 1}`}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "10px",
          }}
        />
        <Typography variant="h6">{item.title}</Typography>
      </animated.div>
    </Grid>
  );
};

export default function HrManagement({ data, useCase }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const navigate = useNavigate();

  const leftAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const rightAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const bottomAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{
        p: "2em",
        display: "flex",
        flexDirection: "column",
        gap: "10em",
        position: "relative",
        mb: "4em",
      }}
    >
      <img
        src="/images/greenPolygon.svg"
        alt="Green Polygon Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          transform: "translate(0, 0)",
          width: "350px",
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          gap: "2em",
          backgroundImage: "url('/images/hrObjectsBg.svg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <animated.div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            flex: 1,
            gap: "1em",
            ...leftAnimation,
          }}
        >
          <Typography variant="h6" sx={{ color: "#2A8282" }}>
            {data?.detailTitle}
          </Typography>
          <Typography variant="h4" sx={{ color: "#001D1D" }}>
            {data?.detailSubTitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#303030", fontWeight: "700" }}
          >
            {data?.detailDescription}
          </Typography>
          {data?.linkname && (
            <Button
              // component={Link}
              // to={data?.link}
              onClick={() => {
                navigate(`/program/${data?.id}`);
              }}
              sx={{
                bgcolor: "#FCC836",
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  bgcolor: "#FFF",
                  outline: "1px solid #FCC836",
                  "& > *": {
                    color: "#FCC836",
                  },
                },
              }}
            >
              <Typography
                variant="button"
                sx={{
                  color: "#040308",
                  transition: "color 0.3s ease-in-out",
                }}
              >
                {data?.linkname}
              </Typography>
            </Button>
          )}
        </animated.div>
        <animated.div style={{ flex: 1, ...rightAnimation }}>
          <Grid container spacing={2}>
            {data?.item?.map((item, index) => (
              <HrItems key={index} item={item} index={index} inView={inView} />
            ))}
          </Grid>
        </animated.div>
      </Box>

      <animated.div
        style={{
          ...bottomAnimation,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${useCase?.BGimage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            padding: "2em",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "20px",
            alignItems: "center",
            gap: "2em",
            flexDirection: { xs: "column", md: "row" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "1em",
              width: { xs: "100%", md: "80%" },
            }}
          >
            <Typography variant="h5" sx={{ color: "#FFF" }}>
              {useCase?.title}
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFF" }}>
              {useCase?.description}
            </Typography>
            {useCase?.linkname && (
              <Button
                onClick={() => {
                  navigate("/sign-up");
                }}
                // component={Link}
                // to={useCase?.link}
                sx={{
                  bgcolor: "#FCC836",
                  transition: "background-color 0.5s ease-in-out",
                  "&:hover": {
                    bgcolor: "#FFF",
                    "& > *": {
                      color: "#FCC836",
                    },
                  },
                }}
              >
                <Typography
                  variant="button"
                  sx={{
                    color: "#040308",
                    transition: "color 0.5s ease-in-out",
                  }}
                >
                  {useCase?.linkname}
                </Typography>
              </Button>
            )}
          </Box>
          <Box sx={{ position: { xs: "relative", md: "absolute", right: 0 } }}>
            <img
              src={useCase?.coverImage}
              alt="Use Case"
              style={{ maxWidth: "450px", height: "auto", maxHeight: "300px" }}
            />
          </Box>
        </Box>
      </animated.div>
    </Box>
  );
}
