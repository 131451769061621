import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import * as yup from "yup";
import { Typography, Container, Button, TextField, Box } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const validationSchema = yup.object({
  email: yup
    .string("email")
    .min(3, "too short")
    .max(1500, "too long")
    .email("Must be valid email ex; @gamil.com")
    .required("Email is required"),
  password: yup
    .string()
    .required("No password provided.")
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const LoginForm = () => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          border: "2px solid #F6F6F6",
          borderRadius: 12,
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography fontWeight={700} sx={{ py: 2 }} variant="h4">
          <Button
            sx={{
              display: "inline",
              position: "absolute",
              left: 0,
              color: "text.primary",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBackIosNewIcon />
          </Button>
          Login into Mini Company
        </Typography>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
            {t("data.email")}
          </Typography>

          <TextField
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            id="email"
            name="email"
            placeholder={t("data.email")}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #F6F6F6",
              },
              mb: 2.5,
            }}
          />
          <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
            {t("data.password")}
          </Typography>

          <TextField
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            id="password"
            name="password"
            type="password"
            placeholder={t("data.password")}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #F6F6F6",
              },
              mb: 2.5,
            }}
          />
          <Button
            sx={{
              color: "text.primary",
              textDecorationLine: "underline",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              navigate("/forgot");
            }}
          >
            Forgot password?
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              my: 1.3,
            }}
          >
            <Button
              type="submit"
              sx={{
                width: 0.85,
                py: 1.5,
                my: 1.3,
                backgroundColor: "#FCC836",
                borderRadius: "5px",
                whiteSpace: "nowrap",
                color: "#040308",
                "&:hover": {
                  color: "#FCC836",
                  backgroundColor: "#FAFAFD",
                  outline: "3px solid #FCC836",
                },
              }}
            >
              <Typography
                color={"text.primary"}
                variant="button"
                fontWeight={700}
              >
                Login
              </Typography>
            </Button>

            <Typography fontWeight={700}>
              Didn`t have an account?
              <Button
                variant="text"
                sx={{
                  color: "text.primary",
                  textDecorationLine: "underline",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  navigate("/sign-up");
                }}
              >
                Create Account
              </Button>
            </Typography>
            <Typography fontWeight={700}>or</Typography>
            <Typography>
              <Box>
                <Button
                  sx={{
                    border: "2px solid #F6F6F6",
                    color: "text.primary",
                    display: "inline-flex",
                    justifyContent: "center",
                    py: 1.5,
                    m: 1.5,
                    backgroundColor: "transparent",
                  }}
                >
                  <Typography variant="h4">
                    <FcGoogle />
                  </Typography>
                </Button>

                <Button
                  sx={{
                    border: "2px solid #F6F6F6",
                    color: "text.primary",
                    display: "inline-flex",
                    justifyContent: "center",
                    m: 1.5,
                    py: 1.5,
                    backgroundColor: "transparent",
                  }}
                >
                  <Typography color={"blue"} variant="h4">
                    <BsFacebook />
                  </Typography>
                </Button>
              </Box>
            </Typography>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default LoginForm;
