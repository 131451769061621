import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "../../Constants/countries";
import { createFilterOptions } from "@mui/material";

import { useTranslation } from "react-i18next";

export default function CountrySelect({
  chooseCode,
  error,
  helperText = "",
  noLabel,
}) {
  const { t } = useTranslation(["common"]);

  const filterOptions = createFilterOptions({
    stringify: ({ label, phone, code }) => `${label} ${phone} +${code}`,
  });

  return (
    <Autocomplete
      defaultValue={countries[0]}
      id="country-select-demo"
      sx={{ width: 1 }}
      options={countries}
      onChange={(event, newValue) => {
        chooseCode(newValue.phone);
      }}
      filterOptions={filterOptions}
      autoHighlight
      getOptionLabel={(option) => "+" + option.phone}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 1, flexShrink: 0 }, px: 1, fontSize: 12 }}
          {...props}
        >
          <img
            loading="lazy"
            width="15"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!noLabel && t("data.country")}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
        />
      )}
    />
  );
}
