import {
  Box,
  Button,
  Divider,
  Menu,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NavBarMenu from "./NavBarMenu";
import { getAllProgramsAction } from "../store/actions/programs";
import { useDispatch, useSelector } from "react-redux";
import { getAllIndustriesAction } from "../store/actions/home";

export default function Header() {
  const [isTop, setIsTop] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [industriesAnchor, setIndustriesAnchor] = useState(null);
  const [programsAnchor, setProgramsAnchor] = useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const location = useLocation();
  const isHomePage =
    location.pathname === "/" || location.pathname === "/app/:id";

  const changeLanguage = useCallback(() => {
    let lang = i18n.language;
    let newLang = "en";
    if (lang === "en") {
      newLang = "ar";
    }

    localStorage.setItem("lng", newLang);
    window?.location?.reload();
  }, [i18n]);

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsTop(scrollTop === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenIndustries = (event) => {
    setIndustriesAnchor(event.currentTarget);
  };

  const handleOpenPrograms = (event) => {
    setProgramsAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIndustriesAnchor(null);
    setProgramsAnchor(null);
  };

  const handleCloseIndustriesMenu = (id) => {
    setAnchorEl(null);
    setIndustriesAnchor(null);
    setProgramsAnchor(null);

    if (id) {
      navigate(`/industries/${id}`);
    }
  };

  const handleCloseProgramsMenu = (id) => {
    setAnchorEl(null);
    setIndustriesAnchor(null);
    setProgramsAnchor(null);

    if (id) {
      navigate(`/program/${id}`);
    }
  };

  const tabs = [
    { name: "home", value: "/" },
    {
      name: "industries",
      value: null,
      arrow: true,
      onClick: handleOpenIndustries,
    },
    {
      name: "programs",
      value: null,
      arrow: true,
      onClick: handleOpenPrograms,
    },
    { name: "use_case", value: "/use-case" },
    // { name: "pricing", value: null },
    { name: "about_us", value: "/about-us" },
    { name: "contact_us", value: "/contact-us" },
  ];

  const industriesData = useSelector((state) => state.home.industries);
  const industriesLoading = useSelector(
    (state) => state.home.industriesLoading
  );
  const industriesError = useSelector((state) => state.home.industriesError);

  const getAllIndustries = useCallback(() => {
    dispatch && getAllIndustriesAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getAllIndustries && getAllIndustries();
  }, [getAllIndustries]);

  const programsData = useSelector((state) => state.allPrograms.data);
  const programsLoading = useSelector((state) => state.allPrograms.loading);
  const programsError = useSelector((state) => state.allPrograms.error);

  const getAllPrograms = useCallback(() => {
    dispatch && getAllProgramsAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getAllPrograms && getAllPrograms();
  }, [getAllPrograms]);

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: isTop ? "transparent" : "white",
        p: "1em 2em",
        position: isHomePage ? "fixed" : "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
        transition:
          "background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out",
        boxShadow: !isTop && "0px 10px 20px rgba(117, 117, 117, 0.18)",
      }}
    >
      <Box>
        <img
          src="/images/miniLogo.png"
          alt="Website Logo"
          onClick={() => navigate("/")}
          style={{ maxHeight: "36px", cursor: "pointer" }}
        />
      </Box>

      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{
          display: { xs: "none", md: "flex" },
          "& .MuiTabs-indicator": {
            backgroundColor: "#FCC836",
          },
          "& .MuiTab-root.Mui-selected": {
            color: "#FCC836",
          },
          "& .MuiTab-root": {
            color: pathname === "/app-details" && isTop ? "#FFF" : "#040308",
            transition: "color 0.3s ease-in-out",
            "&:hover": {
              color: "#FCC836",
            },
          },
          "& .MuiTabs-scrollButtons": {
            color: "#000",
          },
        }}
      >
        {tabs?.map((item, index) => (
          <Tab
            key={index}
            component={Link}
            to={item.value}
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "700" }}>
                  {t(item.name)}
                </Typography>
                {item.arrow && (
                  <KeyboardArrowDown
                    sx={{
                      transform:
                        (industriesAnchor && item.name === "industries") ||
                        (programsAnchor && item.name === "programs")
                          ? "rotate(180deg)"
                          : "rotate(0)",
                    }}
                  />
                )}
              </Box>
            }
            onClick={item.onClick}
          />
        ))}
      </Tabs>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2em",
          "& Button": {
            "&:not(:last-child)": {
              color: pathname === "/app-details" && isTop ? "#FFF" : "#040308",
            },
            "&:hover:not(:last-child)": {
              color: "#FCC836",
              bgcolor: "transparent",
            },
            transition:
              "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
          },
        }}
      >
        <Button
          variant="text"
          onClick={changeLanguage}
          sx={{ bgcolor: "transparent" }}
        >
          <Typography variant="body2" sx={{ fontWeight: "700" }}>
            {t("lang")}
          </Typography>
        </Button>
        <Button
          component={Link}
          to={"/login"}
          variant="text"
          sx={{ bgcolor: "transparent" }}
        >
          <Typography variant="body2" sx={{ fontWeight: "700", color: "#000" }}>
            {t("login")}
          </Typography>
        </Button>
        <Button
          component={Link}
          to={"/sign-up"}
          sx={{
            backgroundColor: "#FCC836",
            borderRadius: "5px",
            p: "0.5em 1.5em",
            whiteSpace: "nowrap",
            color: "#040308",
            "&:hover": {
              color: "#FCC836",
              backgroundColor: "#FAFAFD",
              outline: "1px solid #FCC836",
            },
          }}
        >
          {t("get_started_for_free")}
        </Button>
      </Box>

      <Button
        onClick={handleOpenMenu}
        size="small"
        sx={{
          height: "fit-content",
          width: "fit-content",
          p: "0.5em",
          minWidth: "fit-content",
          display: { xs: "inline-flex", md: "none" },
          bgcolor: "transparent",
        }}
        aria-controls={open ? "header-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MenuIcon sx={{ color: "#FCC836" }} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            p: "0.5em",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#FCC836",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#FCC836",
              bgcolor: "",
            },
            "& .MuiTab-root": {
              color: "#000",
              transition: "color 0.3s ease-in-out",
              "&:hover": {
                color: "#FCC836",
              },
            },
            "& .MuiTabs-scrollButtons": {
              color: "#000",
            },
          }}
        >
          {tabs?.map((e, i) => (
            <Tab
              key={i}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1">{t(e.name)}</Typography>
                  {e.arrow && (
                    <KeyboardArrowDown
                      sx={{
                        transform:
                          (industriesAnchor && e.name === "industries") ||
                          (programsAnchor && e.name === "programs")
                            ? "rotate(180deg)"
                            : "rotate(0)",
                      }}
                    />
                  )}
                </Box>
              }
              onClick={e.onClick}
              value={e?.value}
            />
          ))}
        </Tabs>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
            gap: "1em",
            "& Button": {
              "&:not(:last-child)": {
                color: "#000",
              },
              "&:hover:not(:last-child)": {
                color: "#FCC836",
                bgcolor: "transparent",
              },
              transition:
                "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
            },
          }}
        >
          <Button
            variant="text"
            onClick={changeLanguage}
            sx={{ bgcolor: "transparent" }}
          >
            <Typography variant="body2" sx={{ fontWeight: "700" }}>
              {t("lang")}
            </Typography>
          </Button>
          <Button
            component={Link}
            to={"/login"}
            variant="text"
            sx={{ bgcolor: "transparent", color: "#000" }}
          >
            <Typography variant="body2" sx={{ fontWeight: "700" }}>
              {t("login")}
            </Typography>
          </Button>
          <Button
            component={Link}
            to={"/sign-up"}
            sx={{
              backgroundColor: "#FCC836",
              borderRadius: "5px",
              p: "0.5em 1.5em",
              whiteSpace: "nowrap",
              color: "#040308",
              "&:hover": {
                color: "#FCC836",
                backgroundColor: "#FAFAFD",
                outline: "1px solid #FCC836",
              },
            }}
          >
            <Typography variant="button">
              {t("get_started_for_free")}
            </Typography>
          </Button>
        </Box>
      </Menu>

      <NavBarMenu
        openMenu={industriesAnchor}
        closeMenu={handleCloseIndustriesMenu}
        data={industriesData}
        loading={industriesLoading}
        error={industriesError}
      />

      <NavBarMenu
        openMenu={programsAnchor}
        closeMenu={handleCloseProgramsMenu}
        data={programsData}
        loading={programsLoading}
        error={programsError}
      />
    </Box>
  );
}
