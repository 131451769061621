import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import DiscoveryItems from "../../../components/DiscoveryItems";

export default function Discover({
  data,
  industries,
  handleIndustryIdChange,
  industryAppsData,
  industryId,
}) {
  const industriesLoading = useSelector(
    (state) => state.home.industriesLoading
  );
  const industriesError = useSelector((state) => state.home.industriesError);
  const industryAppsLoading = useSelector(
    (state) => state.home.industryAppsLoading
  );
  const industryAppsError = useSelector(
    (state) => state.home.industryAppsError
  );

  const { t } = useTranslation(["common"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIndustry, setSelectedIndustry] = useState(industryId);
  const [appData, setAppData] = useState();

  useEffect(() => {
    if (industryAppsData) {
      setAppData(industryAppsData);
    }
    handleIndustryIdChange(selectedIndustry);
  }, [industryAppsData, selectedIndustry]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const items = appData?.map((item) => item);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(items?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const currentItems = items?.slice(startIndex, endIndex);

  const handleFilterClick = (id) => {
    setSelectedIndustry(id);
    setCurrentPage(1);
  };

  function PaginationDot({ active, onClick }) {
    return (
      <Box
        style={{
          cursor: "pointer",
          margin: "0 0.2em",
          fontSize: "2em",
          opacity: active ? 1 : 0.2,
          color: active ? "#00CCCC" : "#000",
        }}
        onClick={onClick}
      >
        &bull;
      </Box>
    );
  }

  const renderDots = () => {
    const dots = [];
    for (let i = 1; i <= totalPages; i++) {
      dots.push(
        <PaginationDot
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        />
      );
    }
    return dots;
  };

  return (
    <Box sx={{ position: "relative", p: "2em", zIndex: 1 }}>
      <img
        src="./images/discoverBg.png"
        alt="Component Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          alignItems: "start",
          zIndex: 1,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2A8282" }}>
          {data?.footertitle}
        </Typography>
        <Typography variant="h4" sx={{ color: "#001D1D" }}>
          {data?.footerSubTitle}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#303030" }}>
          {data?.footerBody}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "2em" }}
        ref={ref}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "2em",
            gap: "4em",
            flexWrap: "wrap",
          }}
        >
          {industriesLoading ? (
            <Loader />
          ) : industriesError ? (
            <ErrorMessage message={industriesError} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
                whiteSpace: "nowrap",
                flexWrap: "wrap",
                "& Button": {
                  bgcolor: "#F6F6F6",
                  color: "#1C1C1C",
                  borderRadius: "5px",
                  p: "0.7em",
                  transition:
                    "background-color 0.5s ease-in-out, color 0.5s ease-in-out",
                  "&:hover": {
                    outline: "1px solid #00CCCC",
                  },
                },
                "& .selected": {
                  bgcolor: "#00CCCC",
                  color: "#FAFAFD",
                },
              }}
            >
              <Button
                onClick={() => handleFilterClick(0)}
                className={selectedIndustry === 0 ? "selected" : ""}
              >
                <Typography variant="button">{t("all")}</Typography>
              </Button>
              {industries?.map((industry) => (
                <Button
                  key={industry?.id}
                  onClick={() => handleFilterClick(industry?.id)}
                  className={
                    selectedIndustry === industry?.id ? "selected" : ""
                  }
                >
                  <Typography variant="button">{industry?.title}</Typography>
                </Button>
              ))}
            </Box>
          )}
          <Button
            sx={{
              p: "0.5em 1.2em",
              borderRadius: "5px",
              whiteSpace: "nowrap",
              transition: "background-color 0.5s ease-in-out",
              "&:hover": {
                bgcolor: "#FFF",
                outline: "1px solid #FCC836",
                "& > *": {
                  color: "#FCC836",
                },
              },
            }}
          >
            <Typography
              variant="button"
              sx={{ transition: "color 0.5s ease-in-out" }}
            >
              {t("see_more")}
            </Typography>
          </Button>
        </Box>
        <Box sx={{ p: "1em" }}>
          {industryAppsLoading ? (
            <Loader />
          ) : industryAppsError ? (
            <ErrorMessage message={industriesError} />
          ) : (
            <>
              <Grid container spacing={2}>
                {currentItems?.map((item, index) => (
                  <DiscoveryItems
                    key={item.id}
                    item={item}
                    index={index}
                    inView={inView}
                  />
                ))}
              </Grid>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "1em 0",
                }}
              >
                {renderDots()}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
