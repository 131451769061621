export default function WhyUs2() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.9699 5.91391C20.9347 5.53211 20.6849 5.20311 20.326 5.06841L12.4576 2.11571C12.3679 2.07081 12.2721 2.04061 12.1726 2.02401C11.956 1.98011 11.7288 2.01721 11.5297 2.12061L3.67405 5.06841C3.31505 5.20311 3.06525 5.53211 3.03025 5.91391C3.01955 6.03011 2.08985 17.5312 11.4372 21.8361C11.447 21.8429 11.4577 21.8498 11.4684 21.8556C11.6264 21.9523 11.8059 22.0002 11.9854 22.0002C12.0127 22.0002 12.04 21.9992 12.0674 21.9973C12.2293 21.9866 12.3903 21.9368 12.5337 21.8498C21.9123 17.5556 20.9806 6.03111 20.9699 5.91391ZM16.7227 9.69161L12.4434 14.1662C12.2575 14.3605 12.001 14.4719 11.732 14.4748C11.7282 14.4748 11.7247 14.4748 11.7208 14.4748C11.4557 14.4748 11.2013 14.3693 11.0138 14.1818L9.01385 12.1818C8.62325 11.7912 8.62325 11.1584 9.01385 10.7677C9.40445 10.377 10.0373 10.3771 10.428 10.7677L11.7048 12.045L15.2776 8.30871C15.6589 7.90931 16.2937 7.89561 16.6912 8.27751C17.0904 8.65941 17.1045 9.29221 16.7227 9.69161Z" />
    </svg>
  );
}
