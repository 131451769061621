import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { Box } from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import Pricing from "../details/components/pricing";
import Commissions from "../details/components/commissions";
import Sale from "../details/components/sale";
import Invoicing from "../details/components/invoicing";
import Features from "../details/components/features";
import { getProgramDetailsAction } from "../../store/actions/programs";

export default function ProgramDetails() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.allPrograms.program);
  const loading = useSelector((state) => state.allPrograms.loading);
  const error = useSelector((state) => state.allPrograms.error);

  const { i18n } = useTranslation(["common"]);
  const { id } = useParams();

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const getAppDetails = useCallback(() => {
    dispatch && getProgramDetailsAction({ langId, programId: id })(dispatch);
  }, [dispatch, langId, id]);

  useEffect(() => {
    getAppDetails && getAppDetails();
  }, [getAppDetails]);

  return (
    <Box sx={{ overflow: "hidden" }}>
      {loading ? (
        <Box sx={{ height: "100vh" }}>
          <Loader />
        </Box>
      ) : error ? (
        <ErrorMessage message={error} />
      ) : (
        <>
          <Features
            title={data?.title}
            description={data?.description}
            features={data?.features}
            photos={[{ media: data?.image }]}
            price={data?.plan?.[0]?.price}
            featuresTitle={data?.pages?.titleAppFeature}
            featuresSubtitle={data?.pages?.subTitleAppFeature}
            featuresBody={data?.pages?.detailsAppFeature}
          />
          {data?.details?.details1?.length > 0 && (
            <Invoicing details={data?.details?.details1} />
          )}
          {data?.details?.details2?.length > 0 && (
            <Sale details={data?.details?.details2} />
          )}
          {data?.details?.details3?.length > 0 && (
            <Commissions details={data?.details?.details3} />
          )}
          <Pricing
            plans={data?.plan}
            title={data?.pages?.titleAppPlan}
            subtitle={data?.pages?.detailsAppPlan}
          />
        </>
      )}
    </Box>
  );
}
