import { Box, Button, Divider, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const PlanItems = ({ index, plan, inView }) => {
  const { t, i18n } = useTranslation(["common"]);
  let lang = i18n.language;
  const imageProps = useSpring({
    config: { tension: 20, friction: 0 },
    from: { transform: index === 1 ? "translateY(0px)" : "translateY(0)" },
    to:
      index === 1
        ? { transform: "translateY(-2px)" }
        : { transform: "translateY(0)" },
    loop: { reverse: true },
  });
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform:
      index === 0
        ? inView
          ? "translateX(0)"
          : "translateX(-200px)"
        : index === 1
        ? inView
          ? "translateY(0)"
          : "translateY(200px)"
        : inView
        ? "translateX(0)"
        : "translateX(200px)",
    from: {
      opacity: 0,
      transform:
        index === 0
          ? "translateX(-200px)"
          : index === 1
          ? "translateY(200px)"
          : "translateX(200px)",
    },
    delay: 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <animated.div key={index} style={springProps}>
      <animated.div
        style={{
          padding: index === 1 ? "1em 1em 10em 1em" : "1em",
          backgroundColor: "#FFF",
          borderRadius: "20px",
          transition: "box-shadow 0.5s ease-in-out",
          boxShadow: index === 1 ? "0px 8px 15px rgba(0, 0, 0, 0.1)" : "none",
          maxWidth: "450px",
          imageProps,
        }}
      >
        <Box
          sx={{
            transition: "background-image 0.5s ease-in-out",
            backgroundImage:
              index === 1
                ? "url('/images/standardPlanBg.png')"
                : "url('/images/basicPlanBg.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            p: "2em",
            "& > *": {
              color: "#FFF",
            },
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#FFF",
            }}
          >
            {plan.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#FFF",
            }}
          >
            {plan.description}
          </Typography>
          <Divider
            sx={{
              border: "1px dotted #565656",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5em",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#FFF",
              }}
            >
              {plan.price} {t("egp")}
            </Typography>
            <Box
              sx={{
                bgcolor: index === 1 ? "#3B8A8A" : "#3E3E3E",
                borderRadius: "20px",
                p: "0.5em 1em",

                transition: "background-color 0.5s ease-in-out",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#FFF",
                }}
              >
                {plan.type.toUpperCase()}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            p: "2em",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          {plan?.plantype?.map((feature, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <Box
                sx={{
                  bgcolor: "#181818",
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  p: "0.5em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <KeyboardArrowRight
                  sx={{
                    color: "#FFF",
                    transform: `rotate(${lang === "ar" ? "180deg" : "0"})`,
                  }}
                />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: "#595566",
                }}
              >
                {feature.title}
              </Typography>
            </Box>
          ))}
        </Box>

        <Button
          sx={{
            borderRadius: "20px",
            border: "1px solid #D6D6D6",
            color: index === 1 ? "#040308" : "#181818",
            width: "100%",
            bgcolor: index === 1 ? "#FCC836" : "#fff",
            transition:
              "background-color 0.5s ease-in-out, color 0.5s ease-in-out, outline 0.5s ease-in-out",
            "&:hover": {
              color: "#FCC836",
              outline: "1px solid #FCC836",
            },
            p: "0.6em 0",
          }}
        >
          <Typography variant="button">{t("get_this_plan")}</Typography>
        </Button>
      </animated.div>
    </animated.div>
  );
};

export default function Pricing({ plans, title, subtitle }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: "url('/images/pricingBg.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          p: "2em 2em 8em 2em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2em",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "#2A8282",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#001D1D",
              textAlign: "center",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box
          ref={ref}
          sx={{
            overflow: { xs: "auto", md: "hidden" },
            maxWidth: "100%",
            display: "flex",
            flexWrap: "nowrap",
            gap: "1em",
            justifyContent: "space-between",
          }}
        >
          {plans?.map((plan, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "350px",
              }}
            >
              <PlanItems
                key={index}
                plan={plan}
                index={index}
                inView={inView}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
