import { Box, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

export default function Commissions({ details }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const leftAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const rightAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const imageProps = useSpring({
    config: { tension: 20, friction: 0 },
    from: { transform: "translateY(0px)" },
    to: async (next, cancel) => {
      while (true) {
        await next({ transform: "translateY(-2px)" });
        await next({ transform: "translateY(2px)" });
      }
    },
  });

  return (
    <Box
      ref={ref}
      sx={{
        p: "2em",
        backgroundImage: 'url("/images/commissionsBg.svg")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {details?.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            flexDirection: {
              xs: "column",
              md: index % 2 === 0 ? "row" : "row-reverse",
            },
            gap: { xs: "2em", md: 0 },
          }}
        >
          <animated.div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              gap: "1em",
              ...leftAnimation,
            }}
          >
            <Typography variant="h4" sx={{ color: "#001D1D" }}>
              {item?.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#303030", fontWeight: "700" }}
            >
              {item?.description}
            </Typography>
          </animated.div>

          <animated.div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...rightAnimation,
            }}
          >
            <animated.div style={imageProps}>
              <img
                src={item?.image}
                alt={`commission ${index + 1}`}
                style={{ width: "100%", maxWidth: "500px", height: "auto" }}
              />
            </animated.div>
          </animated.div>
        </Box>
      ))}
    </Box>
  );
}
