import {
  loading,
  error,
  setList,
  setProgramDetails,
  setListIndustry,
} from "../../slices/programsSlice";
import {
  getAllProgramsService,
  getProgramDetailsService,
} from "../../services/programs";

export const getAllProgramsAction =
  ({ langId, industryId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getAllProgramsService({ langId, industryId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        industryId
          ? dispatch(setListIndustry(response))
          : dispatch(setList(response));

        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };

export const getProgramDetailsAction =
  ({ langId, programId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getProgramDetailsService({ langId, programId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setProgramDetails(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };
