import { useTranslation } from "react-i18next";
import { useSpring } from "react-spring";
import { animated } from "react-spring";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Rating,
  Grid,
  Tooltip,
} from "@mui/material";
import { FavoriteBorder } from "@mui/icons-material";
import ImageWithLoader from "./ImageWithLoader";

const ItemCard = ({ index, item, inView, onClick, service }) => {
  const { t } = useTranslation(["common"]);
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(250px)",
    from: { opacity: 0, transform: "translateY(250px)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <Grid
      item
      key={index}
      xs={12}
      sm={6}
      md={4}
      xl={3}
      sx={{ borderRadius: "10px", display: "flex", cursor: "pointer" }}
      onClick={onClick}
    >
      {/* <animated.div style={{ ...springProps, display: "flex" }}> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          transition: "0.5s all",
          "&:hover": {
            boxShadow: "-10px 10px 40px rgba(0, 0, 0, 0.2)",
          },
          borderRadius: "20px",
          width: "100%",
          justifyContent: "space-between",
          bgcolor: "#FFF",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ position: "relative" }}>
            <ImageWithLoader
              component={"img"}
              src={item.image}
              alt={"image"}
              style={{
                borderRadius: "20px",
                height: "250px",
                objectFit: "cover",
                width: "100%",
                zIndex: -1,
                cursor: "pointer",
              }}
              onClick={onClick}
            />
            {/* <IconButton
                sx={{
                  bgcolor: "#FFF",
                  position: "absolute",
                  top: "0.7em",
                  left: "0.7em",
                  zIndex: 1,
                  "&:hover": {
                    bgcolor: "#FFF",
                  },
                  "& > *": {
                    fill: "#3E3E3E",
                  },
                }}
              >
                <FavoriteBorder />
              </IconButton> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              p: "1em",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
                flexWrap: "wrap",
              }}
            >
              {(item.category || service) && (
                <Box
                  key={index}
                  sx={{
                    bgcolor: "#F3F3F3",
                    color: "#1B2129",
                    p: "0.3em 1em",
                    borderRadius: "10px",
                  }}
                >
                  {service ? (
                    <Typography variant="overline" sx={{ fontWeight: "700" }}>
                      package
                    </Typography>
                  ) : (
                    item.category && (
                      <Typography
                        variant="overline"
                        sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                      >
                        {item.category}
                      </Typography>
                    )
                  )}
                </Box>
              )}

              {service ? (
                <Box
                  key={index}
                  sx={{
                    bgcolor: "#F3F3F3",
                    color: "#1B2129",
                    p: "0.5em 1em",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                  >
                    {item.industry}
                  </Typography>
                </Box>
              ) : Array.isArray(item?.industry) ? (
                item?.industry?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      bgcolor: "#F3F3F3",
                      color: "#1B2129",
                      p: "0.5em 1em",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant="overline"
                      sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box
                  key={index}
                  sx={{
                    bgcolor: "#F3F3F3",
                    color: "#1B2129",
                    p: "0.5em 1em",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                  >
                    {item.industry}
                  </Typography>
                </Box>
              )}

              <Typography
                variant="overline"
                sx={{ color: "#2F80ED", fontWeight: "700" }}
              >
                {item.countUser} {t("used")}
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", gap: "5px" }}>
              <Rating name="half-rating" defaultValue={2.5} precision={0.5} />
              <Typography>(4.5)</Typography>
            </Box> */}
            <Typography
              variant="subtitle"
              sx={{ color: "#1C2229", fontWeight: "700" }}
            >
              {item.title}
            </Typography>

            {service && (
              <Box
                sx={{
                  display: "block",
                  position: "relative",
                }}
              >
                <Box>
                  {item?.service?.map((e, i) => (
                    <Tooltip title={e?.type} key={i} placement="top">
                      <img
                        src={e?.icon}
                        alt={e?.type}
                        style={{
                          paddingRight: "7px",
                          width: "45px",
                          height: "35px",
                          borderRadius: "50%",
                        }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              </Box>
            )}
            <Typography variant="body2" sx={{ color: "#706F6F" }}>
              {item?.description?.length > 150
                ? `${item?.description?.substring(0, 150)}...`
                : item?.description}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ p: "1em" }}>
          <Button
            // onClick={onClick}
            sx={{
              bgcolor: "#FF7070",
              borderRadius: "20px",
              width: "fit-content",
              transition: "0.5s all",
              "&:hover": {
                bgcolor: "#FFF",
                outline: "1px solid #FF7070",
                "& .MuiTypography-root": { color: "#FF7070" },
              },
              p: "0.5em 1.2em",
            }}
          >
            <Typography
              variant="button"
              sx={{ color: "#FFF", fontWeight: "700" }}
            >
              {t("create_now")} {item.price} {t("egp")}
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* </animated.div> */}
    </Grid>
  );
};

export default ItemCard;
