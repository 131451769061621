import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const TextWithSeeMore = ({ text, maxChars, isBold }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation(["common"]);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const truncatedText =
    text?.length > maxChars && !expanded
      ? `${text?.substring(0, maxChars)}...`
      : text;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <Typography
          variant="body1"
          sx={{
            color: "#3E3E3E",
            fontWeight: isBold ? "700" : "400",
          }}
        >
          {truncatedText}
        </Typography>
        {text?.length > maxChars && (
          <Box
            onClick={handleClick}
            sx={{
              cursor: "pointer",
              display: "flex",
              width: "fit-content",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ color: "#3E3E3E" }}>
              {expanded ? t("see_less") : t("see_more")}
            </Typography>
            {expanded ? (
              <ExpandLess sx={{ color: "#3E3E3E" }} />
            ) : (
              <ExpandMore sx={{ color: "#3E3E3E" }} />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default TextWithSeeMore;
