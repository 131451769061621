export default function WhyUs1() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2731 3.2351C10.5675 3.1783 8.84431 3.64364 7.35658 4.6367C5.68564 5.75324 4.51791 7.40203 3.98004 9.24523C3.83151 9.2271 3.65364 9.2351 3.44244 9.29484C2.65391 9.51857 2.06084 10.1647 1.79418 10.7338C1.44831 11.4754 1.30244 12.4562 1.45631 13.4687C1.60911 14.478 2.03551 15.31 2.56884 15.8266C3.10378 16.3434 3.70778 16.5114 4.30778 16.3852C5.20111 16.1946 5.64404 16.0519 5.51898 15.2196L4.91338 11.1852C5.03551 9.00177 6.16591 6.9463 8.05658 5.6823C10.5872 3.9919 13.9051 4.10017 16.319 5.95324C17.9982 7.2407 18.9731 9.16977 19.0856 11.1948L18.6622 14.017C17.7176 16.6018 15.376 18.3922 12.6664 18.6463H10.8622C10.3966 18.6463 10.0216 19.0212 10.0216 19.4863V19.9295C10.0216 20.3948 10.3966 20.7698 10.8622 20.7698H13.1371C13.6024 20.7698 13.9758 20.3948 13.9758 19.9295V19.6978C16.019 19.1991 17.8024 17.9434 18.9638 16.1922L19.6926 16.3855C20.2856 16.5394 20.8968 16.3434 21.4315 15.8268C21.9648 15.31 22.391 14.4783 22.544 13.469C22.6984 12.4564 22.5483 11.4772 22.2064 10.734C21.8632 9.99084 21.352 9.51884 20.7619 9.3495C20.5147 9.2783 20.2464 9.25217 20.0163 9.24523C19.5299 7.57857 18.5286 6.06284 17.0854 4.95617C15.6664 3.86737 13.9787 3.2911 12.2731 3.2351Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9358 10.4933C15.5372 10.4933 16.0246 10.9807 16.026 11.5837C16.0246 12.185 15.5372 12.6738 14.9358 12.6738C14.3329 12.6738 13.8441 12.185 13.8441 11.5837C13.8441 10.981 14.3332 10.4933 14.9358 10.4933ZM11.9998 10.4933C12.6025 10.4933 13.09 10.9807 13.09 11.5837C13.09 12.185 12.6025 12.6738 11.9998 12.6738C11.3966 12.6738 10.9092 12.185 10.9092 11.5837C10.9092 10.981 11.3966 10.4933 11.9998 10.4933ZM9.06488 10.4933C9.66622 10.4933 10.155 10.9807 10.155 11.5837C10.155 12.185 9.66622 12.6738 9.06488 12.6738C8.46222 12.6738 7.97449 12.185 7.97449 11.5837C7.97449 10.981 8.46222 10.4933 9.06488 10.4933ZM11.9998 5.81006C8.80248 5.81006 6.22595 8.30179 6.22595 11.5837C6.22595 13.1599 6.82195 14.553 7.79289 15.5751L7.44835 17.1197C7.33475 17.6279 7.68729 17.9698 8.14409 17.7154L9.65235 16.8741C10.3692 17.1853 11.162 17.3573 11.9998 17.3573C15.1982 17.3573 17.7732 14.8671 17.7732 11.5837C17.7732 8.30179 15.1982 5.81006 11.9998 5.81006Z"
      />
    </svg>
  );
}
