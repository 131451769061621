import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import homeReducer from "../slices/homeSlice";
import appDetailsReducer from "../slices/appDetailsSlice";
import programsReducer from "../slices/programsSlice";
import useCasesReducer from "../slices/useCasesSlice";
import seoReducer from "../slices/seoSlice";
import appsReducer from "../slices/apps";
import faqReducer from "../slices/faq";
import aboutUsReducer from "../slices/aboutUs";
import privacyPolicyReducer from "../slices/privacy";

const store = configureStore({
  reducer: {
    home: homeReducer,
    appDetails: appDetailsReducer,
    allPrograms: programsReducer,
    useCases: useCasesReducer,
    seo: seoReducer,
    apps: appsReducer,
    faq: faqReducer,
    aboutUs: aboutUsReducer,
    privacyPolicy: privacyPolicyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
