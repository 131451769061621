import axios from "axios";

export const getAllUseCasesService = async ({ langId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/useCase/all`,
      {
        langId: langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
