import { Box, Button, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate } from "react-router-dom";

export default function Operation({ data }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const navigate = useNavigate();

  const leftAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const rightAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const AccountingItems = ({ index, item, inView }) => {
    const springProps = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(100%)",
      from: { opacity: 0, transform: "translateX(100%)" },
      delay: index * 350,
      config: { mass: 1, tension: 120, friction: 14 },
    });
    return (
      <animated.div
        key={index}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.38)",
          borderRadius: "10px",
          padding: "0.5em",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "1em",
          ...springProps,
        }}
      >
        <img
          src={item.icon}
          alt={`Operation ${index + 1}`}
          style={{ width: "50px", height: "50px" }}
        />
        <Typography variant="subtitle1" sx={{ color: "#FFFFFF" }}>
          {item.title}
        </Typography>
      </animated.div>
    );
  };

  return (
    <Box
      ref={ref}
      sx={{
        backgroundImage: `url(${data?.BGimage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        p: "2em",
        display: "flex",
        justifyContent: "space-between",
        gap: "1em",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <animated.div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          gap: "1em",
          ...leftAnimation,
        }}
      >
        <Typography variant="subtitle1" sx={{ color: "#F5F5FB" }}>
          {data?.detailTitle}
        </Typography>
        <Typography variant="h4" sx={{ color: "#FFF" }}>
          {data?.detailSubTitle}
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF" }}>
          {data?.detailDescription}
        </Typography>
        {data?.linkname && (
          <Button
            // component={Link}
            // to={data?.link}
            onClick={() => {
              navigate(`/program/${data?.id}`);
            }}
            sx={{
              bgcolor: "#FCC836",
              transition: "background-color 0.5s ease-in-out",
              "&:hover": {
                bgcolor: "#FFF",
                outline: "1px solid #FCC836",
                "& > *": { color: "#FCC836" },
              },
            }}
          >
            <Typography
              variant="button"
              sx={{ color: "#040308", transition: "color 0.5s ease-in-out" }}
            >
              {data?.linkname}
            </Typography>
          </Button>
        )}
      </animated.div>
      <animated.div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "1em",
          ...rightAnimation,
        }}
      >
        {data?.item?.map((item, index) => (
          <AccountingItems
            key={index}
            item={item}
            index={index}
            inView={inView}
          />
        ))}
      </animated.div>
    </Box>
  );
}
