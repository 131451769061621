import { Button, Container, Modal, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { success } from "../../Assets";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1,
  maxWidth: "579px",
  bgcolor: "background.paper",
  pt: 3,
  borderRadius: "1em",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  pb: 5,
};

const FormSentModal = ({ handleClose, open, subTitle }) => {
  const { t } = useTranslation(["common"]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Container>
        <Paper sx={style}>
          <img src={success} alt="" />
          <Typography
            fontWeight="bold"
            variant="h6"
            maxWidth="443px"
            sx={{ my: 3 }}
            textAlign="center"
          >
            {t("service_request.successTitle")}
          </Typography>

          {subTitle && (
            <Typography
              color="text.secondary"
              fontWeight="bold"
              textAlign="center"
              sx={{ pb: 2.5 }}
            >
              {subTitle}
            </Typography>
          )}

          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ px: 3, py: 1 }}
          >
            {t("close")}
          </Button>
        </Paper>
      </Container>
    </Modal>
  );
};

export default FormSentModal;
