import { Box, CardMedia, Skeleton } from "@mui/material";
import { useCallback, useState } from "react";

const ImageWithLoader = ({
  variant = "rounded",
  style,
  component,
  sx,
  loading,
  disableLoader,
  ...props
}) => {
  const [imgLoading, setImgLoading] = useState(true);
  const onImageLoad = useCallback(() => setImgLoading(false), []);

  return (
    <Box sx={{ width: 1, height: 1 }}>
      {imgLoading && component !== "video" && (
        <Skeleton variant={variant} width={"100%"} height={"100%"} />
      )}
      {component === "video" && (
        <CardMedia
          {...props}
          loading={loading}
          sx={{
            ...(style || {}),
            ...(sx || {}),
          }}
          component={component}
          alt=""
        />
      )}
      {component !== "video" && (
        <img
          {...props}
          loading={loading}
          style={{
            ...(style || {}),
            ...(sx || {}),
            visibility: imgLoading ? "hidden" : "visible",
          }}
          onLoad={onImageLoad}
          alt=""
        />
      )}
    </Box>
  );
};

export default ImageWithLoader;
