import axios from "axios";

export const getAppDetailsService = async ({ langId, appId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/apps/${appId}`,
      {
        langId: langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
