import {
  loading,
  error,
  setList,
  appsLoading,
  appsError,
  setAppsList,
} from "../../slices/apps";
import {
  getAllCategoriesService,
  getIndustryAppsService,
} from "../../services/apps";
import { getIndustryService } from "../../services/home";
import {
  industriesError,
  industriesLoading,
  setIndustryList,
} from "../../slices/homeSlice";

export const getAllCategoriesAction =
  ({ langId }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await getAllCategoriesService({ langId });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(setList(response));
        return;
      }
    } catch (error) {
      dispatch(error(error.message));
      return;
    }
  };

export const getIndustryAppAction =
  ({ langId, categoryId, industryId }) =>
  async (dispatch) => {
    dispatch(appsLoading());
    try {
      const response = await getIndustryAppsService({
        langId,
        categoryId,
        industryId,
      });
      if (response?.error) {
        dispatch(appsError(response.error));
        return;
      } else {
        dispatch(setAppsList(response));
        return;
      }
    } catch (error) {
      dispatch(appsError(error.message));
      return;
    }
  };

export const getIndustryAction =
  ({ langId, categoryId, industryId }) =>
  async (dispatch) => {
    dispatch(industriesLoading());
    try {
      const response = await getIndustryService({
        langId,
        categoryId,
        industryId,
      });
      if (response?.error) {
        dispatch(industriesError(response.error));
        return;
      } else {
        dispatch(setIndustryList(response));
        return;
      }
    } catch (error) {
      dispatch(industriesError(error.message));
      return;
    }
  };
