import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
export const CustomAccordion = ({
  title,
  children,
  disabled,
  defaultExpanded = false,
}) => {
  const theme = useTheme();
  const classes = makeStyles(() => ({
    root: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "primary.white",
      },
    },
  }))();

  return (
    <Accordion
      //   TransitionProps={{ unmountOnExit: true }}
      sx={{ mt: 2 }}
      elevation={0}
      className={classes.root}
      disabled={disabled}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        sx={{
          border: 0,
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            "& .Remove": {
              display: "flex",
            },
            "& .Add": {
              display: "none",
            },
          },
        }}
        expandIcon={
          <Box>
            <AddIcon
              className="Add"
              color="text.primary"
              sx={{ display: "flex" }}
            />{" "}
            <RemoveIcon
              className="Remove"
              color="text.primary"
              sx={{ display: "none" }}
            />
          </Box>
        }
      >
        <Typography variant="h6" fontWeight={700} color={"text.green"}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography fontWeight={700}>{children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
