import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import FormSentModal from "../../components/FormSentModal";
// import { contactUsService } from "../../services/index";

const validationSchema = yup.object({
  name: yup
    .string("name")
    .min(3, "too short")
    .max(50, "too long")
    .required("name is required"),
  email: yup
    .string("email")
    .min(3, "too short")
    .max(1500, "too long")
    .email("Must be valid email ex: @gamil.com")
    .required("Email is required"),
  message: yup
    .string("message")
    .min(3, "too short")
    .max(350, "too long")
    .required("message is required"),
});

const ContactUsForm = () => {
  const { t } = useTranslation(["common"]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => setOpen(false), []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // try {
      //   const data = await contactUsService(values);
      //   if (data?.status?.code === 200) {
      //     handleOpen();
      //     formik.resetForm();
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
    },
  });

  return (
    <Box
      sx={{
        height: "650px",
        border: "3px solid #F6F6F6",
        borderRadius: "16px",
        p: 5,
        pt: 3,
        maxWidth: "755px",
        width: 1,
      }}
    >
      <Typography
        variant="h2"
        color="text.green"
        fontWeight="bold"
        textAlign="center"
        sx={{ mb: 4 }}
      >
        {t("contact_us")}
      </Typography>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
          {t("data.name")}
        </Typography>
        <TextField
          fullWidth
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          id="name"
          name="name"
          // label={t("data.name")}
          placeholder={t("data.name")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #F6F6F6",
            },
            mb: 2.5,
          }}
        />

        <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
          {t("data.email")}
        </Typography>
        <TextField
          fullWidth
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          id="email"
          name="email"
          // label={t("data.email")}
          placeholder={t("data.email")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #F6F6F6",
            },
            mb: 2.5,
          }}
        />

        <Typography color="text.darkGreen" fontWeight="bold" sx={{ mb: 2 }}>
          {t("data.message")}
        </Typography>
        <TextField
          fullWidth
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
          id="message"
          name="message"
          // label={t("data.message")}
          placeholder={t("data.message")}
          multiline
          rows={5}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #F6F6F6",
            },
            mb: 2.5,
          }}
        />

        <Button fullWidth type="submit" sx={{ p: 2 }} variant="contained">
          {t("send")}
        </Button>
      </form>

      <FormSentModal handleClose={handleClose} open={open} />
    </Box>
  );
};

export default ContactUsForm;
