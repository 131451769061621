import { Box, Button, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DiscoveryItems = ({ index, item, inView }) => {
  const { t } = useTranslation(["common"]);
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(50px)",
    from: { opacity: 0, transform: "translateY(50px)" },
    delay: index * 350,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <Grid item xs={12} sm={6} md={4} xl={3} sx={{ borderRadius: "10px" }}>
      <animated.div style={springProps}>
        <Box
          component={Link}
          to={`/app/${item.id}`}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            transition: "0.5s all",
            "&:hover": {
              boxShadow: "-10px 10px 40px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            },
            borderRadius: "20px",
            p: "1em",
            textDecoration: "none",
          }}
        >
          <img
            src={item.image}
            alt={`App ${index + 1}`}
            style={{
              borderRadius: "20px",
              height: "250px",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
              flexWrap: "wrap",
            }}
          >
            <Box
              key={index}
              sx={{
                bgcolor: "#F3F3F3",
                color: "#1B2129",
                p: "0.3em 1em",
                borderRadius: "10px",
              }}
            >
              <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                {item.category}
              </Typography>
            </Box>

            {item?.industry?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#F3F3F3",
                  color: "#1B2129",
                  p: "0.5em 1em",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                  {item.title}
                </Typography>
              </Box>
            ))}

            <Typography
              variant="caption"
              sx={{ color: "#2F80ED", fontWeight: "700" }}
            >
              {item.countUser} {t("used")}
            </Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ color: "#1C2229" }}>
            {item.title}
          </Typography>
          <Button
            sx={{
              bgcolor: "#FCC836",
              borderRadius: "5px",
              width: "fit-content",
              transition: "background-color 0.5s ease-in-out",
              "&:hover": {
                bgcolor: "#FFF",
                outline: "1px solid #FCC836",
                "& .MuiTypography-root": {
                  color: "#FCC836",
                  transition: "color 0.5s ease-in-out",
                },
              },
              p: "0.5em 1.2em",
            }}
          >
            <Typography variant="button" sx={{ color: "#FFF" }}>
              {t("create_now")} {item.price} {t("egp")}
            </Typography>
          </Button>
        </Box>
      </animated.div>
    </Grid>
  );
};
export default DiscoveryItems;
