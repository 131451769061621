import axios from "axios";

export const getFaqService = async ({ langId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/faq/view`,
      {
        langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
