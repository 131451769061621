import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    homeLoading(state) {
      state.loading = true;
      state.error = null;
    },
    homeError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setHomeList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },

    industriesLoading(state) {
      state.industriesLoading = true;
      state.industriesError = null;
    },
    industriesError(state, action) {
      state.industriesLoading = false;
      state.industriesError = action.payload;
    },
    setAllIndustriesList: (state, action) => {
      state.industriesLoading = false;
      state.industries = action.payload;
    },

    industryAppsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    industryAppsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setIndustryAppsList: (state, action) => {
      state.loading = false;
      state.industryApps = action.payload;
    },
    setIndustryList(state, action) {
      state.loading = false;
      state.industryList = action.payload;
    },
  },
});

export const {
  homeLoading,
  setIndustryList,
  homeError,
  setHomeList,
  industriesLoading,
  industriesError,
  setAllIndustriesList,
  industryAppsLoading,
  industryAppsError,
  setIndustryAppsList,
} = homeSlice.actions;

export default homeSlice.reducer;
