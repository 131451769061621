import axios from "axios";

export const getAllProgramsService = async ({ langId, industryId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/program/all`,
      {
        langId: langId,
        industryId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getProgramDetailsService = async ({ langId, programId }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/program/${programId}`,
      {
        langId: langId,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
