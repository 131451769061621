import { Box } from "@mui/material";
import TopBanner from "./components/topBanner";
import UseCases from "./components/cases";
import { useCallback, useEffect } from "react";
import { getAllUseCasesAction } from "../../store/actions/useCases";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";

export default function UseCase() {
  const { i18n } = useTranslation(["common"]);
  const dispatch = useDispatch();

  let lang = i18n.language;
  let langId;
  if (lang === "en") {
    langId = 1;
  } else if (lang === "ar") {
    langId = 2;
  }

  const useCasesData = useSelector((state) => state.useCases.data);
  const useCasesLoading = useSelector((state) => state.useCases.loading);
  const useCasesError = useSelector((state) => state.useCases.error);

  const getAllUseCases = useCallback(() => {
    dispatch && getAllUseCasesAction({ langId })(dispatch);
  }, [dispatch, langId]);

  useEffect(() => {
    getAllUseCases && getAllUseCases();
  }, [getAllUseCases]);

  return (
    <Box>
      {useCasesLoading ? (
        <Box sx={{ height: "100vh" }}>
          <Loader />
        </Box>
      ) : (
        <>
          <TopBanner data={useCasesData?.pages} />
          <UseCases
            data={useCasesData?.alluseCase}
            loading={useCasesLoading}
            error={useCasesError}
          />
        </>
      )}
    </Box>
  );
}
