import { Box, Button, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Generator({ data }) {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const boxAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const imageAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        p: "10em 4em 6em 4em",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column-reverse", md: "row" },
        gap: { xs: "2em", md: "0" },
        overflow: "hidden",
      }}
    >
      <img
        src="./images/polygonBg.png"
        alt="Polygon Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          transform: "translate(-15%, -15%)",
        }}
      />

      <img
        src="./images/generatorBg.png"
        alt="AI Generator Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />

      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          flex: 1,
          ...boxAnimation,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2A8282" }}>
          {data?.titleAi}
        </Typography>
        <Typography variant="h4" sx={{ color: "#001D1D" }}>
          {data?.subTitleAi}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#303030" }}>
          {data?.bodyAi}
        </Typography>
        <Button
          onClick={() => navigate("/Generator")}
          sx={{
            bgcolor: "#FCC836",
            p: "0.5em 2em",
            borderRadius: "5px",
            width: "fit-content",
            transition: "0.5s all",
            "&:hover": {
              bgcolor: "#FFF",
              outline: "1px solid #FCC836",
              "& > *": {
                color: "#FCC836",
              },
            },
          }}
        >
          <Typography
            variant="button"
            sx={{
              color: "#040308",
              transition: "0.5s all",
            }}
          >
            {t("go_ai_generator")}
          </Typography>
        </Button>
      </animated.div>

      <animated.div
        style={{
          flex: 1,
          ...imageAnimation,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={data?.imageAi}
          alt="AI Generator"
          style={{ width: "100%", maxWidth: "600px", maxHeight: "400px" }}
        />
      </animated.div>
    </Box>
  );
}
