import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import TextWithSeeMore from "../../../components/textSeeMore";

const FeaturesItems = ({ index, feature, inView }) => {
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(50px)",
    from: { opacity: 0, transform: "translateY(50px)" },
    delay: index * 150,
    config: { mass: 1, tension: 120, friction: 14 },
  });
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      key={index}
      sx={{ display: "flex" }}
    >
      <animated.div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          outline: "1px solid #EAEAEA",
          padding: "1em",
          borderRadius: "20px",
          gap: "1em",
          backgroundColor: "#FFF",
          width: "100%",
          ...springProps,
        }}
      >
        <Box
          sx={{
            p: "1em",
            bgcolor: "#181818",
            color: "#FFF",
            borderRadius: "50%",
            width: "15px",
            height: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">{index + 1}</Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            color: "#181818",
          }}
        >
          {feature.title}
        </Typography>
      </animated.div>
    </Grid>
  );
};

export default function OurPackage({
  title,
  description,
  features,
  photos,
  price,
  details,
  featuresTitle,
  featuresSubtitle,
  featuresBody,
}) {
  const { t } = useTranslation(["common"]);
  const [mainImage, setMainImage] = useState(photos?.[0]?.media ?? null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fading, setFading] = useState(false);

  const handleClick = (index) => {
    const image = photos[index]?.media;
    setSelectedIndex(index);
    setFading(true);
    setTimeout(() => {
      setMainImage(image);
      setFading(false);
    }, 300);
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const imageProps = useSpring({
    config: { tension: 20, friction: 0 },
    from: { transform: "translateY(0px)" },
    to: async (next, cancel) => {
      while (true) {
        await next({ transform: "translateY(-2px)" });
        await next({ transform: "translateY(2px)" });
      }
    },
  });

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: "url('/images/packageHeaderBg.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          p: "0 2em",
          height: "400px",
          position: "relative",
          minHeight: "400px",
        }}
      />
      <Box
        sx={{
          mt: { xs: "-320px" },
          position: "relative",
          overflow: "hidden",
          p: "2em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "stretch",
            gap: "2em",
            zIndex: 2,
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              position: "relative",
              flex: 2,
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <animated.div
              style={{
                ...imageProps,
                height: "500px",
                position: "relative",
                borderRadius: "15px",
                overflow: "hidden",
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.4)",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: -1,
                  backgroundImage: `url(${mainImage})`,
                  backgroundSize: "cover",
                  filter: "blur(10px)",
                  borderRadius: "15px",
                }}
              />
              <img
                src={mainImage}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "15px",
                  opacity: fading ? 0 : 1,
                  transition: "opacity 0.3s ease-in-out",
                }}
              />
            </animated.div>
            <Box style={{ display: "flex", width: "100%", gap: "1em" }}>
              {photos?.map((image, index) => (
                <img
                  key={index}
                  src={image?.media}
                  alt={`${index + 1}`}
                  style={{
                    flexBasis: "calc(25% - 1em)",
                    maxWidth: "calc(25% - 0.75em)",
                    objectFit: "cover",
                    borderRadius: "10px",
                    cursor: "pointer",
                    outline:
                      index === selectedIndex ? "2px solid  #00CCCC" : "none",
                    opacity: index === selectedIndex ? 1 : 0.35,
                    transition: "opacity 0.3s ease-in-out",
                    height: "100px",
                  }}
                  onClick={() => handleClick(index)}
                />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              p: "1em",
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.64) 0%, rgba(255, 255, 255, 0.3904) 100%)",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              borderRadius: "15px",
              flex: 1,
            }}
          >
            <Box
              sx={{
                bgcolor: "#00CCCC",
                borderRadius: "5px",
                width: "fit-content",
                p: "0.2em 0.7em",
              }}
            >
              <Typography>
                {price} {t("egp")}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              sx={{
                color: "#010C19",
              }}
            >
              {title}
            </Typography>
            <TextWithSeeMore text={description} maxChars={100} isBold={true} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "1em",
                "& Button": {
                  borderRadius: "5px",
                  p: "0.5em 1.5em",
                  transition: "0.5s all",
                },
              }}
            >
              <Button
                sx={{
                  bgcolor: "#FCC836",
                  color: "#040308",
                  "&:hover": {
                    bgcolor: darken(0.2, "#FCC836"),
                    color: darken(0.2, "#040308"),
                  },
                }}
              >
                <Typography variant="button">
                  {t("buy_now")} {price} {t("egp")}
                </Typography>
              </Button>
              <Button
                sx={{
                  bgcolor: "#FFFBFB",
                  color: "#040308",
                  "&:hover": {
                    bgcolor: "#D9D9D9",
                    color: darken(0.2, "#040308"),
                  },
                }}
              >
                <Typography variant="button">{t("preview")}</Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                marginTop: "5em",
              }}
            >
              <Box style={{ textAlign: "center" }}>
                {photos?.map((image, index) => (
                  <Box
                    key={index}
                    style={{
                      backgroundColor: "#00CCCC",
                      width: "50px",
                      height: "5px",
                      borderRadius: "2px",
                      opacity: index === selectedIndex ? 1 : 0.2,
                      transition: "opacity 0.3s",
                      marginRight: "8px",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleClick(index);
                    }}
                  />
                ))}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontWeight: "700",
                  }}
                >
                  {`${(selectedIndex + 1)
                    .toString()
                    .padStart(2, "0")}/${photos?.length
                    .toString()
                    .padStart(2, "0")}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <img
          src="/images/polygonBg.png"
          alt="Polygon Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            transform: "translate(-15%, -15%)",
          }}
        />

        <img
          src="/images/greenCircle.svg"
          alt="Green Circle Background"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: -1,
            transform: "translate(15%, -50%)",
          }}
        />
        <Box
          ref={ref}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            p: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "100%", md: "80%", lg: "70%", xl: "50%" },
                display: "flex",
                flexDirection: "column",
                gap: "1em",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#2A8282",
                }}
              >
                {featuresTitle}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#001D1D",
                }}
              >
                {featuresSubtitle}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#303030",
                  fontWeight: "700",
                }}
              >
                {featuresBody}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {features?.map((feature, index) => (
              <FeaturesItems
                key={index}
                feature={feature}
                index={index}
                inView={inView}
              />
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
