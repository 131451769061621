import { Box, Button, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

export default function Invoicing({ details }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const leftAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(-100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const rightAnimation = useSpring({
    transform: inView ? "translateX(0)" : "translateX(100%)",
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: 350,
  });

  const imageProps = useSpring({
    config: { tension: 20, friction: 0 },
    from: { transform: "translateY(0px)" },
    to: async (next, cancel) => {
      while (true) {
        await next({ transform: "translateY(-2px)" });
        await next({ transform: "translateY(2px)" });
      }
    },
  });

  return (
    <Box sx={{ p: "2em", position: "relative" }}>
      <img
        src="/images/graphBg.svg"
        alt="Graph Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: -2,
          opacity: "56%",
          transform: "translate(0, 50%)",
          width: "100%",
        }}
      />
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2em",
          position: "relative",
        }}
      >
        <img
          src="/images/purpleCircle.svg"
          alt="Purple Circle Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            transform: "translate(-15%, 60%)",
          }}
        />

        {details?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "stretch",
              flexDirection: {
                xs: "column",
                md: index % 2 === 0 ? "row" : "row-reverse",
              },
              gap: { xs: "2em", md: 0 },
            }}
          >
            <animated.div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                gap: "1em",
                ...leftAnimation,
              }}
            >
              <Typography variant="h4" sx={{ color: "#001D1D" }}>
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#303030", fontWeight: "700" }}
              >
                {item.description}
              </Typography>
              {item?.linkName && (
                <Button
                  sx={{
                    borderRadius: "5px",
                    bgcolor: "#FCC836",
                    p: "0.5em 1.5em",
                  }}
                >
                  <Typography variant="button" sx={{ color: "#040308" }}>
                    {item.linkName}
                  </Typography>
                </Button>
              )}
            </animated.div>

            <animated.div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 5,
                ...rightAnimation,
              }}
            >
              <animated.div style={imageProps}>
                <img
                  src={item.image}
                  alt={`invoice ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "600px",
                  }}
                />
              </animated.div>
            </animated.div>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
